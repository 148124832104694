/**
 * 模块名称：申请业绩调整
 * @author zuoshen@372163.com
 */
import React, { useState, useEffect} from 'react'
import api from '@/api'
import {
	Descriptions,
	Button,
	Input,
	Select,
	message,
	Spin,
	Form,
	InputNumber
} from 'antd' 
import BtnGroup from '@/components/BtnGroup'
import { parseSearch, deepCopyObj} from '@/utils'
import { isNumber } from 'lodash'

const FormItem = Form.Item
const dItem = Descriptions.item
const Option = Select.Option

let perfDist = [['','']]
let _staffInfo = []

const Apply = (props) => {

	const { history, location } = props
	const { getFieldDecorator, validateFields, setFieldsValue, getFieldValue } = props.form

	const [detail, setDetail] = useState({})//全部数据 
	const [orderInfo, setOrderInfo] = useState({})//订单信息 
	const [loading, setLoading] = useState(true) 
	const [perfDistribution, setPerfDistribution] = useState([['','']]) 
	const [staffList, setStaffList] = useState([])
	const [staffInfo, setStaffInfo] = useState([]) 
	const [addFlag, setAddFlag]  = useState(false)

	useEffect(() => {
		localStorage.removeItem('getStaffByName')
		perfDist = [['','']]
		if (location.state.id) {
			getPerformanceAdjDetail(location.state.id)
		} else if (location.state.orderId && location.state.perId) {
			getBaseInfo(location.state.orderId, location.state.perId)
		} else {
			history.goBack()
		}
	}, []) 

	useEffect(() => {
		const values = {}
		perfDistribution.map((item,index) => {
			values['performance' + index] = item[0]
			values['staffs' + index] = item[1] 
		})
		setFieldsValue(values)
	}, [perfDistribution])
	 

	//初始化申请信息
	const getBaseInfo = (orderId, perId) => {
		setLoading(true)
		api.perfBaseData({ orderId: orderId, perId: perId }).then(res => {
			if (JSON.stringify(res) !== "[]") {
				setOrderInfo(res)
				setFieldsValue({
					'colProfit':res.colprofit,
					'colCost':res.colcost,
					'colRefund':res.colrefund
				})
			}
			setLoading(false)
		}).catch(() => setLoading(false))
		setPerfDistribution(perfDist)  
	} 
	 
	//选择的staff
	const onChangeStaff = (option, value, keyindex) => {
		_staffInfo[keyindex] = JSON.parse(option.props['data-value'])  
		setStaffInfo(_staffInfo)    
	} 

	const checkMore = ()=>{
		let more=false,stf = [];
		perfDist.some(item=>{
			stf['p'+item[1]] = stf['p'+item[1]]==null?1:stf['p'+item[1]]+1 
		    if(stf['p'+item[1]]>1){ 
					more=true; 
			    return false;
			} 
		})   
		if(more){ 
			setAddFlag(true);
			message.warning('有重复的分成人，请检查');
		}else{
			setAddFlag(false);
		}
	}

	//人员列表滚动 
	const getStaffList = (val,ign=0) => { 
		ign==0 && setAddFlag(true)
		
		if (val!='' && !isNumber(val)) { 
			if (!(val.indexOf(" ") == -1)) {
				return message.warning('请输入正确内容,不要输入空格！')
			}
			return api.onOfficeWorkGetStaffByName({ name: val, type: 'ignore' }).then(res => {
				setStaffList(res.list)
			})
		} 
	}


	//获取详情
	const getPerformanceAdjDetail = (id) => {
		// id = 41 //假数据
		api.perfAdjDetail({ id: id, type: 1 }).then(res => {
			if (parseSearch(location.search).msgId) {
				api.setMessageReaded({ ids: [parseSearch(location.search).msgId] })
			}
			if (JSON.stringify(res) !== "[]") {
				let data = Object.assign({}, res)
				setDetail(data)
				if (data) {
					setOrderInfo(data.orderInfo)
				}
				setFieldsValue({
					'colCost': res.colCost && res.colCost
					, 'colProfit': res.colProfit && res.colProfit
					, 'colRefund': res.colRefund && res.colRefund
				})

				data.allotInfo.map((item,key)=>{
                     
					perfDist[key] = [item.cost,item.userId,key,{'staffName':item.userName,'leaderName':item.leader,'departmentName':item.department}]
					getStaffList(item.userName,1)
					_staffInfo[key] = perfDist[key][3]
				})
				
				setPerfDistribution(deepCopyObj(perfDist)) 
				setStaffInfo(deepCopyObj(_staffInfo))
			}
			setLoading(false)
		})
	}

	//增加分成人
	const addPerfDist = ()=> { 
		if (JSON.parse(localStorage.getItem("getStaffByName"))) {
			let arr = JSON.parse(localStorage.getItem("getStaffByName"))
			setStaffList(arr)
		}
		if(checkPerfDist()){ 
			perfDist.push(['',''])   
			setPerfDistribution(deepCopyObj(perfDist))   
		} 
	}

	const getStaffLists = () => {
		if (localStorage.getItem("getStaffByName") && localStorage.getItem("getStaffByName").length > 0) {
			let arr = JSON.parse(localStorage.getItem("getStaffByName"))
			let newArr = [...arr, ...staffList]
			const ids = 'id'
			const getStaffByName = newArr.reduce((all, next) => all.some((atom) => atom[ids] == next[ids]) ? all : [...all, next],[])
			localStorage.setItem("getStaffByName", JSON.stringify(getStaffByName))
		} else {
			localStorage.setItem("getStaffByName", JSON.stringify(staffList))
		}
	}

	const checkPerfDist = (ign=0)=>{ 
			//先记录上一个的信息
			let index = perfDist.length-1;  
			if(index >=0 )
			{ 
				let check = true, error = 0 
				for(let i = 0 ;i < perfDist.length;i++)
				{ 
					let per = getFieldValue('performance' + i);
					let stf =  getFieldValue('staffs' + i)
		
					if((!per && per !== 0) || !stf)
					{
						error = i + 1
						check = false
						break; 
					}else{  
						perfDist[i] = [per,stf,i,_staffInfo[i]]; 
					}
				} 
				if(!check && ign == 0) {
					message.warning('第' + (error) + '行的业绩和分成人为必填，请检查');
					return false;
			    }
			}   
				checkMore()
			return true;
	}

	const delPerfDist = (keyindex)=>{  
		let arr = JSON.parse(localStorage.getItem("getStaffByName"))
		if (arr) {
		  setStaffList(arr)
		}
		if(perfDist[keyindex]){  
		   perfDist.splice(keyindex,1) 
		   staffInfo.splice(keyindex,1)
		   setPerfDistribution(deepCopyObj(perfDist))
		   setStaffInfo(staffInfo)
		   checkMore()
		}

	}

	const searchSubmit = (e) => {
		e.preventDefault()
		
    if(addFlag){
			message.warning('请先完成操作，谢谢');
			return false
		}
		
		validateFields((err, vals) => { 
			let data = { ...vals } 
			if (!err) { 
				let allotInfo = [], total = 0, yeji = []
				perfDist.map((item,key)=>{
				  allotInfo.push(
						{
							'cost':getFieldValue('performance'+key),
							'userId':getFieldValue('staffs'+key),
							'userName':item[3]['staffName'],
							'leader':item[3]['leaderName'],
							'department':item[3]['departmentName']
						}
					)
					yeji.push(getFieldValue('performance'+key))
					total += +getFieldValue('performance'+key)
				})
				//console.log(perfDist,allotInfo);return false;
				let params={
					orderId:location.state.orderId ? location.state.orderId:orderInfo.id,
					perId:location.state.perId?location.state.perId:detail.perId,
					colProfit:data.colProfit?data.colProfit:0,
					colCost:data.colCost?data.colCost:0,
					colRefund:data.colRefund?data.colRefund:0,
					allotInfo:allotInfo,
					remark:data.remark?data.remark:'' 
				}
				if (!yeji.map(Number).every(num => num <= 0) && !yeji.map(Number).every(num => num >= 0)) {
					return message.error("请注意：同一订单分成人业绩不允许有正有负的情况，即必须同为正数或同为负数")
				}
				if ((Math.abs((Number(total)).toFixed(2) - (Number(params.colProfit)).toFixed(2))).toFixed(2) > 0.02)
				{
					setAddFlag(false);
					message.warning('分成总和应等于个人利润，请检查');
					return false;
				}

				

				if((((+orderInfo.integral)+(+orderInfo.colactualprice)).toFixed(2) - (+params.colProfit+(+params.colRefund)+(+params.colCost)).toFixed(2))!==0)
				{
					setAddFlag(false);
					message.warning('实收数据不正确，积分+实收=个人利润+成本+调整项，请检查');
					return false;
				}
				
				if(location.state.id)
				{
					params = {...params,id:location.state.id}
				}
				setLoading(true)
				api.perfAdjApplie(params).then(()=>{
					message.success('提交成功')
					history.push(`/performance/adjustment`)
					setLoading(false)
				}).catch(() => {
					setLoading(false)
				})
				setAddFlag(false)
			} 
		})
	}
	return (
		<Spin spinning={loading}>
			<Form > 
			<div className="add-orderDirect-detail">
				<Descriptions title={`业绩调整 订单编号: ${orderInfo.number || ''}`} layout="vertical" className="pro-detail-title" column={5} style={{ borderBottom: "none" }}>
					<dItem label="客户名称">{orderInfo.client}</dItem>
					<dItem label="OAID">{orderInfo.clientId}</dItem>
					<dItem label="厂商ID">{orderInfo.vendor}</dItem>
					{orderInfo.type == 0 && <dItem label="所属销售">{orderInfo.relSalerName}</dItem>}
					{orderInfo.type == 0 && <dItem label="所属客服">{orderInfo.relServicerName}</dItem>}
					{orderInfo.type == 1 && <dItem label="供应商所属开发">{orderInfo.relAgentStaffName}</dItem>}
					{orderInfo.type == 1 && <dItem label="提单人">{orderInfo.userName}</dItem>}
				</Descriptions>
				<div className="blank-line"></div>
				<div className="ask-info">
					<div className="record-list-title">订单信息</div>
					{orderInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={3} style={{ borderBottom: "none" }}>
						<dItem label="项目">{orderInfo.projectName}</dItem>
						<dItem label="产品">{orderInfo.productName}</dItem>
						<dItem label="业务类型">{orderInfo.businessName}</dItem>
						<dItem label="业绩类型">{orderInfo.figtypeName}</dItem>
						{orderInfo.type == '0' && <dItem label="是否撤资重开订单">{orderInfo.isodfdivestName}</dItem>}
						{orderInfo.type == '0' && <dItem label="是否退转订单">{orderInfo.isodftrunName}</dItem>}
						{orderInfo.type == '0' && <dItem label="是否框架订单">{orderInfo.isodfkjiaName}</dItem>}
						{orderInfo.type == '0' && <dItem label="销售主项">{orderInfo.majorminorName}</dItem>}
						{orderInfo.type == '1' && <dItem label="代理商名称">
							<div>{orderInfo.agent}</div>
							<div>ID: {orderInfo.agentId}</div>
						</dItem>} 
					</Descriptions> : '-'}
				</div>
				<div className="blank-line"></div>
				<div className="ask-info">
					<div className="record-list-title">订单收款信息</div>
					<Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
						<dItem label="市场价">{orderInfo.colmarketprice}</dItem>
						<dItem label="实收">{orderInfo.colactualprice}</dItem>
						<dItem label="积分">{orderInfo.integral}</dItem>
						<dItem label="折扣">{orderInfo.colrebate}</dItem>
						<dItem label={<><span className="required-label">*</span> 个人利润</>}>
							<FormItem label="" colon={false} style={{ width: '100%' }}>
								{getFieldDecorator('colProfit', {						
									rules: [{ required: true, message: '请输入个人利润' }]
								})(<InputNumber
									step={0.01}
									// min={0}
									style={{ width: '200px' }}
									placeholder="请输入个人利润"
								/>)}
							</FormItem>
						</dItem>
						<dItem label={<><span className="required-label">*</span> 成本</>}>
							<FormItem label="" colon={false} style={{ width: '100%' }}>
								{getFieldDecorator('colCost', {
									rules: [{ required: true, message: '请输入成本' }]
								})(<InputNumber
									step={0.01}
									min={0}
									style={{ width: '200px' }}
									placeholder="请输入成本"
								/>)}
							</FormItem>
						</dItem>
						<dItem label={<><span className="required-label">*</span> 调整项</>}>
							<FormItem label="" colon={false} style={{ width: '100%' }}>
								{getFieldDecorator('colRefund', {
									rules: [{ required: true, message: '请输入调整项' }]
								})(<InputNumber
									step={0.01}
									min={0}
									style={{ width: '200px' }}
									placeholder="请输入调整项"
								/>)}
							</FormItem>
						</dItem>
					</Descriptions>
					<Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
						<dItem label="提单人">{orderInfo.userName}</dItem>
						<dItem label="直接主管">{orderInfo.userLeaderName}</dItem>
						<dItem label="部门">{orderInfo.userDeptName}</dItem>
					</Descriptions>
				</div>
				<div className="blank-line"></div>
				<div className="ask-info">
					<div className="record-list-title">业绩分配信息 
					  <Button disabled={addFlag} onClick={() => addPerfDist()} className="orange-btn" style={{float:'right'}}>增加分成人</Button>
					</div>
					{perfDistribution.map((item, keyindex) => {   
						return (
							<Descriptions key={item+keyindex} layout="vertical" column={4} className="pro-detail-title" style={{ borderBottom: "none" }}>
								<dItem label={<><span className="required-label">*</span>业绩</>}>
									<FormItem label="" colon={false} style={{ width: '100%' }}>
										{getFieldDecorator('performance'+keyindex, {
											//initialValue: item[0]||'',
											rules: [{ required: true, message: '请输入业绩' }]
										})(<InputNumber
											step={0.01}
											// min={0}  
											style={{ width: '150px', marginLeft: '-4px' }}
											placeholder="请输入业绩"
											onBlur={() => checkPerfDist(1)}
										/>)}
									</FormItem>
								</dItem>

								<dItem label={<><span className="required-label">*</span>分成人</>}>
									<FormItem>
										{getFieldDecorator('staffs'+keyindex, {
											//initialValue:  item[1]||'',
											rules: [{ required: true, message: '请输入分成人' }]
										})(
											<Select
												showSearch
												showArrow
												placeholder="请输入分成人"
												onSearch={getStaffList}  
												// onBlur={(value,option)=>{getStaffList(value);checkPerfDist(1) }} 
												onSelect={(value,option)=>{getStaffList(value);checkPerfDist(1);getStaffLists()}} 
												onChange={(value, option) => onChangeStaff(option, value, keyindex)} 
												
												notFoundContent='未搜索到相关分成人'
												filterOption={false}
												style={{ width: 150, marginLeft: '-4px' }}
											>
												{staffList.map(item =>
											    <Option key={item.id} value={item.id} data-value={JSON.stringify(item)} >
														{item.staffName}
												  </Option>
												)}
											</Select>
										)}
									</FormItem> 
								</dItem>
								<dItem label="直接主管"><div className="dItem-txt-box">{staffInfo[keyindex] && staffInfo[keyindex].leaderName}</div></dItem>
								<dItem label="部门" >
							    <div className="dItem-txt-box">{staffInfo[keyindex] && staffInfo[keyindex].departmentName}
								    {keyindex>0 &&<a onClick={()=>{delPerfDist(keyindex)}} className='orange-btn' style={{float:'right',marginTop:'-10px',padding:'5px 15px',borderRadius:'5px',display:'block'}}>删除</a>}
									</div> 
					      </dItem>
							</Descriptions>
						)
					})}				
				</div> 
				<Descriptions title="备注" layout="horizontal" className="pro-detail-smailtitle">
					<div className="content">
					<FormItem label="" colon={false} style={{width: '100%'}}>
						{getFieldDecorator('remark',  {
								initialValue:'',
								rules: [{ required: false, message: '' }]
							})(<Input.TextArea
							style={{minWidth: '900px', minHeight: '6em'}}
							maxLength={300}
							placeholder="输入备注"
						/>)}
					</FormItem>
					<BtnGroup onCancel={() => {history.go(-1)}} onConfirm={searchSubmit} disabled={loading} htmlType="submit" confirmName = '提交' right="to-right" loading={false} />
				</div>
			</Descriptions> 
			</div>		
			</Form>
		</Spin>
	)
}

export default Form.create()(Apply)