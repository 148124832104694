/**
 * 模块名称: 消耗业绩时间节点管理
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import moment from 'moment'
import {
  Table,
  Form,
  Icon,
  Input,
  Button,
  Select,
  message,
  Alert,
  DatePicker,
  Tooltip,
	Switch,
	Drawer,
	Spin
} from 'antd'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import AddNodeManage from './module/AddNodeManage'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker

let pageSize = 10
let currentPage = 1
let searchParamsTmp = {
  limit: pageSize, 
  page: currentPage
}

const NodeManage = (props) => {
    const { history, match, location } = props

    const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  
		const [authList, setAuthList] = useState([])
  
    const [count, setCount] = useState(0)
    const [list, setList] = useState([])
    const [filterProject, setFilterProject] = useState([])
    const [filterProduct, setFilterProduct] = useState([])
		const [editVisible, seteditVisible] = useState(false)
		const [addType, setAddType] = useState(0)
  	const [title,setTitle] = useState('消耗业绩时间节点-新增')
		const [loading, setLoading] = useState(false)
		const [formLoading, setformLoading] = useState(false)
		const [editId, setEditId] = useState(null)
    const [showAll, setShowAll] = useState(false)
    const [projectId, setProjectId] = useState('')

    const search = parseSearch(location.search)
    
    useEffect(() => {
      currentPage = search.page ? +search.page : 1
      pageSize = search.limit ? +search.limit : 10
      searchParamsTmp = {
        limit: pageSize, 
        page: currentPage
			}

      getListFilter()
      getPerformanceTimeList()
			api.getPageAuth().then(list => setAuthList(list))
    },[])
  
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      }
    }
  
    //点击重置
    const onReset = () => {
      currentPage = 1
      history.replace(match.path)
      searchParamsTmp = {
        limit: pageSize, 
        page: currentPage
      }
      setProjectId('')
      resetFields()
      getPerformanceTimeList()
    }
  
    //分页点击
    const onChangeTable = (pagination, filters, sorter) => {
      searchParamsTmp.page = currentPage = pagination.current
      searchParamsTmp.limit = pageSize = pagination.pageSize
      history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
      getPerformanceTimeList()
    }
  
    //获取列表
    const getPerformanceTimeList = () => {
      setLoading(true)
      api.getPerformanceTimeList(
        searchParamsTmp
      ).then(res => {
        setCount(res.count)
        setList(res.list)
        setLoading(false)
      }).catch(() => {
        setLoading(false)
      })
    }
   
    //获取筛选项
    const getListFilter = () => {
      api.getsysProjectChilds({ pid: 0}).then(res => { 
        setFilterProject(res)
      }).catch(() => {
      })
		}
  
    //数据转换
    const transData = (vals) => {
      if(vals !== undefined){
        for(let i in vals){
          if(vals[i] !== undefined){
            if(i == 'nodeTime'){
              searchParamsTmp.nodeTimeMin = moment(vals[i][0]).format('YYYY-MM-DD')
              searchParamsTmp.nodeTimeMax = moment(vals[i][1]).format('YYYY-MM-DD')
            }else{
              searchParamsTmp[i] = vals[i]
            }
          }
        }
      }
		}
		
    //点击筛选
    const searchSubmit = (e) => {
      e.preventDefault()
      validateFields((err, vals) => {
        //console.log(vals)
        transData(vals)
        if (!err) {
          searchParamsTmp.page = currentPage = 1
          history.replace(match.path)
          getPerformanceTimeList()
        }
      })
    }
  
    //新增
    const onAdd = () => {
			setAddType(0)
      seteditVisible(true)
      setEditId(null)
			setTitle('消耗业绩时间节点-新增')
    }

    //编辑
    const onEdit = (data) => {
			seteditVisible(true)
			setAddType(1)
			setEditId(data.id)
			setTitle('消耗业绩时间节点-编辑')
		}
		
		//禁用启用
		const onChangeStatus = (val) => {
      setLoading(true)
      if (!val.isOpen) {
        api.setPerformanceTimeOpen({ id: val.id}).then(res => {
          setLoading(false)
          message.success('开启成功')
          getPerformanceTimeList()
        }).catch(err => setLoading(false))
      } else {
        api.setPerformanceTimeClose({ id: val.id }).then(res => {
          setLoading(false)
          message.success('关闭成功')
          getPerformanceTimeList()
        }).catch(err => setLoading(false))
      }
    }

    //获取选取的项目名称
    const onProject = (key) => {
      // console.log(key)
      setProjectId(key)
      filterProduct && setFieldsValue({ productId: ''})
    }
    
    //筛选项获取产品列表
    const onProduct = () => {
      if (projectId !== '') {
        setLoading(true)
        api.getsysProjectChilds({ pid: projectId}).then(res => { 
          setFilterProduct(res)
          setLoading(false)
        }).catch(() => setLoading(false))
      }
    }
  
    return (
      <>
        <div className="search-askforleave-wrap">
          <h4 className="title">筛选选项：</h4>
          {
            !showAll ? <span className="askforleave-filter-control" onClick={() => setShowAll(true)}>展开<Icon type="down" /></span>
            : <span className="askforleave-filter-control" onClick={() => setShowAll(false)}>收起<Icon type="up" /></span>
          }
          <Form onSubmit={searchSubmit} {...formItemLayout}>
            <div className={`form-box${showAll ? ' askforleave-form-auto' : ''}`}>
              <FormItem label="员工编号">
                {getFieldDecorator('staffNo')(<Input 
                  placeholder="请输入员工编号" 
                />)}
              </FormItem>
              <FormItem label="公司用名">
                {getFieldDecorator('staffName')(<Input 
                  placeholder="请输入公司用名" 
                />)}
              </FormItem> 
              <FormItem label="客户名称">
                {getFieldDecorator('customerName')(<Input 
                  placeholder="请输入客户名称" 
                />)}
              </FormItem>
              <FormItem label="客户ID">
                {getFieldDecorator('customerId')(<Input 
                  placeholder="请输入客户ID" 
                />)}
              </FormItem>
              <FormItem label="项目">
                {getFieldDecorator('projectId')(<Select
                  placeholder="请选择"
                  onSelect={onProject}
                  // onDropdownVisibleChange={boll => boll && setProjectId('')}
                >
                  {
                    filterProject && filterProject.map((item, index) => {
                      return <Option key={item.id}>{item.name}</Option>
                    })
                  }
                </Select>)}
              </FormItem> 
              <FormItem label="产品">
                {getFieldDecorator('productId')(<Select
                  placeholder="请选择"
                  onDropdownVisibleChange={boll => boll && onProduct()}
                >
                  {
                    filterProduct && filterProduct.map((item, index) => {
                      return <Option key={item.id}>{item.name}</Option>
                    })
                  }
                </Select>)}
              </FormItem>
              <FormItem label="消耗业绩节点日期">
                {getFieldDecorator('nodeTime', {
                })(<RangePicker allowClear={false} />)}
              </FormItem>  
            </div>
            <FormItem className="btns" label=" " colon={false}>
              <div className="staff-filter-btns">
                <Button onClick={onReset}>重置</Button>
                <Button type="primary" htmlType="submit">筛选</Button>
              </div>
            </FormItem>   
          </Form>
        </div>
        <div className="line"></div>
        <div className="add-wrap">
          <Auth auths={authList} code="operate">
					  <Button type="primary" style={{ marginLeft: 15 }} onClick={onAdd}>新增</Button>
          </Auth>
        </div>
        <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon/>
        <Table
          size="small"
					dataSource={list}
					rowKey="id"
					loading={loading}
					pagination={{
						pageSize,
						total: count,
						current: currentPage,
						showQuickJumper: true,
						showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
					}}
					onChange={onChangeTable}
				>
					<Column title="员工编号" dataIndex="staffNo"  /> 
					<Column title="公司用名" dataIndex="staffName" />
					<Column title="客户名称" dataIndex="customerName" width={180}/>
					<Column title="项目" dataIndex="projectName" />
					<Column title="产品" dataIndex="productName"  /> 
					<Column title="消耗业绩节点日期" dataIndex="nodeTime" />
					<Column title="剩余消耗业绩金额（元）" dataIndex="lostAmount" />
					<Column title="剩余消耗业绩时间（天）" dataIndex="lostDays" />
					<Column title="操作" width={120} key="oprate" render={(text, record) => {
						return <>
              <Auth auths={authList} code="operate">
                <Tooltip title="编辑" placement="bottom">
                  <Icon type="edit" style={{ color: '#1890FF', fontSize: '16px' }} onClick={() => onEdit(record)}/>
                </Tooltip>
                {''}
                <Switch 
                  // defaultChecked
                  checked={record.isOpen ? true : false}
                  size="small"
                  onChange={() => onChangeStatus(record)} 
                  style={{ marginLeft: '20px', marginTop: '-5px' }}
                />
              </Auth>
						</>
					}} />
        </Table>
				<Drawer
					title={title}
					width={600}
					onClose={() => seteditVisible(false)}
					visible={editVisible}
					destroyOnClose={true}
				>
					<Spin spinning={formLoading}>
						<AddNodeManage
							setformLoading={setformLoading}
							seteditVisible={seteditVisible}
							getPerformanceTimeList={getPerformanceTimeList}
							addType={addType}
							editId={editId}
						/>
					</Spin>
				</Drawer>
      </>
    )
}

export default Form.create()(NodeManage)