/**
 * 模块名称: 消耗业绩产品配置
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Icon,
  Button,
  Select,
  Alert,
	Switch,
	Tooltip,
	Drawer,
  Spin,
  message
} from 'antd'

import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import AddProductSet from './module/AddProductSet'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option

let pageSize = 10
let currentPage = 1
let pageSizeS = 10
let currentPageS = 1
let ids = ''
let searchParamsTmp = {
  limit: pageSize, 
  page: currentPage
}

let searchParamsTmpS = {
  limit: pageSizeS, 
  page: currentPageS
}

const ProductSet = (props) => {
  const { history, match, location } = props

  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form

	const [authList, setAuthList] = useState([])
  const [count, setCount] = useState(0)
  const [recordCount, setRecordCount] = useState(0)
  const [list, setList] = useState([])
  const [recordList, setRecordList] = useState([])
	const [filterProject, setFilterProject] = useState([])
	const [filterproduct, setFilterproduct] = useState([])
	const [loading, setLoading] = useState(false)
	const [formLoading, setformLoading] = useState(false)
	const [editVisible, seteditVisible] = useState(false)
	const [editId, setEditId] = useState(null)
	const [addType, setAddType] = useState(0)
  const [title,setTitle] = useState('业绩产品-新增')
  const [recordVisible, setRecordVisible] = useState(false)
	const search = parseSearch(location.search)

	//项目
  const [projectName, setProjectName] = useState('')
	const [projectId, setProjectId] = useState('')
	
	//产品
  const [productId, setProductId] = useState('')
	
  useEffect(() => {
    ids = ''
    currentPage = search.page ? +search.page : 1
		pageSize = search.limit ? +search.limit : 10

    currentPageS = search.page ? +search.page : 1
		pageSizeS = search.limit ? +search.limit : 10

    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage
    }
    searchParamsTmpS = {
      limit: pageSizeS, 
      page: currentPageS
    }
    getListFilter()
    getPerformanceProductConfigList()
		api.getPageAuth().then(list => setAuthList(list))
  },[])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  //点击重置
  const onReset = () => {
    currentPage = 1
    history.replace(match.path)
    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage
		}
    searchParamsTmpS = {
      limit: pageSizeS, 
      page: currentPageS
		}
    ids = ''
    resetFields()
    setProjectId('')
    setFilterproduct('')
    getPerformanceProductConfigList()
  }

  //分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getPerformanceProductConfigList()
  }

  //分页点击
  const onChangeTables = (pagination, filters, sorter) => {
    searchParamsTmpS.page = currentPageS = pagination.current
    searchParamsTmpS.limit = pageSizeS = pagination.pageSize
    // history.replace(match.path + '?page=' + currentPageS + '&limit=' + pageSizeS)
    operatingRecord(ids)
  }

  //获取业绩产品配置列表
  const getPerformanceProductConfigList = () => {
    setLoading(true)
    api.getPerformanceProductConfigList(
			searchParamsTmp
		).then(res => { 
			// console.log(res)
      setCount(res.count)
      setList(res.list)
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }
 
  //获取筛选项
  const getListFilter = () => {
		setLoading(true)
		api.getProjectOrProduct({ open: 0, projectId: 0 }).then(res => {
      // console.log(res)
			setFilterProject(res)
			setLoading(false)
    }).catch(() =>setLoading(false))
  }

  //点击筛选
  const searchSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      // console.log(vals)
      if (!err) {
				currentPage = 1
				searchParamsTmp = {
          page: currentPage,
          limit: pageSize,
					projectId: vals.projectId ? vals.projectId : '',
					productId: vals.productId ? vals.productId : ''
				}
        history.replace(match.path)
        getPerformanceProductConfigList()
      }
    })
	}

	//改变状态
  const onChangeStatus = (id, status) => {
    // console.log(status)
    setLoading(true)
    api.getPerformanceProductConfigChangeStatus({id: Number(id), status: status }).then(() => {
      setLoading(false)
      if (status) {
        message.success('开启成功')
      } else {
        message.success('关闭成功')
      }
      getPerformanceProductConfigList()
    }).catch(err => setLoading(false))
	}
	
	
	const closeDrawer = () => {
    seteditVisible(false)
	}
	
	//打开新增弹窗
	const onAdd = () => {
    setAddType(0)
    setEditId(null)
		seteditVisible(true)
		setTitle('业绩产品-新增')
	}
	
	//选择产品
	const onproductList = () => {
		setLoading(true)
		if (projectId !== '') {
			api.getProjectOrProduct({ open: 0, projectId: projectId }).then(res => {
				// console.log(res)
				setLoading(false)
				setFilterproduct(res)
			})
		} else {
      message.warning('请先选择项目')
			setLoading(false)
		}
	}

	//选中项目
  const onprojectName = (key, value) => {
    // console.log(key, value.props.children)
    productId != '' && setFieldsValue({productId: ''})
    setProjectName(value.props.children)
    setProjectId(key)
	}
	
	//选中产品名称
  const onproductName = (key) => {
    setProductId(key)
	}
	
	//编辑
	const onEdit = (data) => {
		// console.log(data.id)
		seteditVisible(true)
		setAddType(1)
		setEditId(data.id)
		setTitle('业绩产品-编辑')
  }
  
  //操作记录
  const closeRecord = () => {
    setRecordVisible(false)
  }

  //操作记录按钮
  const onRecord = (data) => {
    pageSizeS = 10
    currentPageS = 1  
    searchParamsTmpS = {
      limit: pageSizeS, 
      page: currentPageS
    }
    // console.log(data.id)
    ids = data.id
    setRecordVisible(true)
    setformLoading(true)
    operatingRecord(ids)
  }

  const operatingRecord = (ids) => {
    api.getPerformanceProductConfigLogList({ ppcId: ids, ...searchParamsTmpS }).then(res => {
      // console.log(res)
      setformLoading(false)
      setRecordCount(res.count)
      setRecordList(res.list.map((item, index) => ({...item, id: index})))
    }).catch(err => setformLoading(false))
  }

  return (
    <>
      <div className="search-askforleave-wrap">
        <h4 className="title">筛选选项：</h4>
        <Form onSubmit={searchSubmit} {...formItemLayout}>
          <div className="form-box">
            <FormItem label="项目">
              {getFieldDecorator('projectId')(<Select
								placeholder="全部"
								showSearch={true} 
								optionFilterProp="children"
								onSelect={onprojectName}
              >
                {
                  filterProject && filterProject.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="产品">
              {getFieldDecorator('productId')(<Select
								placeholder="全部" 
								showSearch={true} 
								optionFilterProp="children"
								onSelect={onproductName}
								onDropdownVisibleChange={bool => bool && onproductList()}
              >
                {
                  filterproduct && filterproduct.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem> 
						<FormItem className="btns" label=" " colon={false}>
							<Button onClick={onReset}>重置</Button>
							<Button type="primary" htmlType="submit">筛选</Button>
						</FormItem>
          </div>
        </Form>
      </div>
			<div className="line"></div>
			<div className="add-wrap">
        <Auth auths={authList} code="operate">
					<Button type="primary" style={{ marginLeft: 15 }} onClick={onAdd}>新增</Button>
        </Auth>
      </div>
			<Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon/>
			<Table
        size="small"
				dataSource={list}
				rowKey="id"
				loading={loading}
				pagination={{
					pageSize,
					total: count,
					current: currentPage,
					showQuickJumper: true,
					showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
				}}
				onChange={onChangeTable}
			>
				<Column title="项目名称" dataIndex="project"  /> 
				<Column title="产品名称" dataIndex="product" />
				<Column title="时间自然日（天）" dataIndex="gap" />
				<Column title="金额（元）" dataIndex="money" />
				<Column title="操作" key="oprate" render={(text, record) => {
					return <>
            <Auth auths={authList} code="operate">
              <Tooltip title="编辑" placement="bottom">
                <Icon type="edit" style={{ color: '#1890FF', fontSize: '16px' }} onClick={() => onEdit(record)}/>
              </Tooltip>
              {''}
              <Switch 
                // defaultChecked={record.statusFlag}
                checked={record.statusFlag}
                size="small"
                onChange={val => onChangeStatus(record.id, record.status)} 
                style={{ marginLeft: '20px', marginTop: '-5px' }}
              />
            </Auth>
            {''}
            <span style={{ marginLeft: '30px', color: '#439DDD', cursor: 'pointer' }} onClick={() => onRecord(record)}>操作记录</span>
					</>
				}} />
      </Table>
			<Drawer
        title={title}
        width={600}
        onClose={closeDrawer}
        visible={editVisible}
        destroyOnClose={true}
      >
        <Spin spinning={formLoading}>
          <AddProductSet
            setformLoading={setformLoading}
            seteditVisible={seteditVisible}
            getPerformanceProductConfigList={getPerformanceProductConfigList}
            addType={addType}
            editId={editId}
          />
        </Spin>
      </Drawer>
      <Drawer
        title="操作记录"
        width={800}
        onClose={closeRecord}
        visible={recordVisible}
        destroyOnClose={true}
      >
        <Spin spinning={formLoading}>
          <div className="product-set-record">
            <Table
              size="small"
              dataSource={recordList}
              rowKey={record=>record.id}
              loading={loading}
              className="corp-table"
              pagination={{
                pageSizeS,
                total: recordCount,
                current: currentPageS,
                showQuickJumper: true,
                showSizeChanger: true,
                pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
              }}
              onChange={onChangeTables}
            >
              <Column title="操作时间" dataIndex="addTime"  width={160}/> 
              <Column title="操作人" dataIndex="userName" width={80}/>
              <Column title="操作内容" dataIndex="content" />
            </Table>
            <Button className="product-set-record-btn" onClick={ () => setRecordVisible(false)}>取消</Button>
          </div>
        </Spin>
      </Drawer>
    </>
  )
}

export default Form.create()(ProductSet)