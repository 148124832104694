/**
 * 模块名称: 业绩调整
 * @author wangyunhai@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import moment from 'moment'
import { Link } from 'react-router-dom'
import {
  Table,
  Form,
  Icon,
  Input,
  Button,
  Select,
  Modal,
  message,
  Alert,
  DatePicker,
  Popconfirm,
  Switch,
  Tooltip
} from 'antd'

import CorpFilter from 'components/CorpFilter' 
import Export from 'components/Export'
import Auth from '@/components/AuthMiddleware'
import { parseSearch } from 'utils'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker

let pageSize = 10
let currentPage = 1
let companyId = []
let searchParamsTmp = {
  limit: pageSize, 
  page: currentPage,
  corpId: companyId
}
let shouldMe = null
let apiOperateTimer = true

let storeFilterName = ''
let storeFilterContent = {}
let allFilter = {}
let selectedIds = ''

const Personal = (props) => {
  const productsRef = useRef()
  const { history, match, location } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const [authList, setAuthList] = useState([])
  const [filterName, setFilterName] = useState(undefined)
  const [count, setCount] = useState(0)
  const [list, setList] = useState([])
  const [filter, setFilter] = useState({})
  const [loading, setLoading] = useState(false)
  const [departmentList, setDepartmentList] = useState([])
  const [filterModelvisible, setFilterModelvisible] = useState(false)
  const [filterContent, setFilterContent] = useState([])
  const [showAll, setShowAll] = useState(false)
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)
  const search = parseSearch(props.location.search)
  const [shouldMeDis, setShouldMeDis] = useState(true)//风控，防止用户频繁点击switch
  const [showExport, setShowExport] = useState(false)

  useEffect(() => {
    // console.log(history.location.state)
		if (!history.location.state) {
      currentPage = search.page ? +search.page : 1
      pageSize = search.limit ? +search.limit : 10
      searchParamsTmp = {
        limit: pageSize, 
        page: currentPage
      }
    } else {
      currentPage = search.page ? +search.page : currentPage
      pageSize = search.limit ? +search.limit : pageSize
      commonalityDepartment()
      // console.log(searchParamsTmp)
      setTimeout(() => {
        setFieldsValue({
          staffNo: searchParamsTmp.staffNo ? searchParamsTmp.staffNo : undefined,
          userName: searchParamsTmp.userName ? searchParamsTmp.userName : undefined,
          orderNo: searchParamsTmp.orderNo ? searchParamsTmp.orderNo : undefined,
          department: searchParamsTmp.department ? searchParamsTmp.department : undefined,
          orderType: searchParamsTmp.orderType ? searchParamsTmp.orderType : undefined,
          majorminor: searchParamsTmp.majorminor ? searchParamsTmp.majorminor : undefined,
          figtype: searchParamsTmp.figtype ? searchParamsTmp.figtype : undefined,
          applyTime: searchParamsTmp.applySTime ? [moment(searchParamsTmp.applySTime), moment(searchParamsTmp.applyETime)] : undefined,
          auditTime: searchParamsTmp.auditSTime ? [moment(searchParamsTmp.auditSTime), moment(searchParamsTmp.auditETime)] : undefined,
        })
      }, 1000)
    }
    shouldMe = null
    apiOperateTimer = true
    storeFilterName = ''
    storeFilterContent = {}
    getListFilter()
    getPerfAdjList()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  //部门公共
  const commonalityDepartment = () => {
    api.getDepartment({limit: global.paramsLimit, companyId}).then(res => {
      setDepartmentList(res.list)
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  //点击选择所属公司
  const onChangeCorp = (data) => {
    //console.log('onChangeCorp', data)
    setDepartmentList([])
    setFieldsValue({'department': undefined})
    currentPage = 1
    history.replace(match.path)
    if(data.length) {
      let arr = []
      data.forEach(item => {
        arr.push(item.id)
      })
      companyId = [].concat(arr)
      searchParamsTmp = {
        limit: pageSize, 
        page: currentPage,
        corpId: companyId
      }
    }else{
      companyId = []
      searchParamsTmp = {
        limit: pageSize, 
        page: currentPage
      }
    }
    getPerfAdjList()
  }
  //点击重置
  const onReset = () => {
    currentPage = 1
    shouldMe = null
    history.replace(match.path)
    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage,
      corpId: companyId
    }
    resetFields()
    setFilterName(undefined)
    setStatusValName(undefined)
    getPerfAdjList()
  }

  //分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getPerfAdjList()
  }

  //获取列表
  const getPerfAdjList = () => {
    if(apiOperateTimer){
      setLoading(true)
      if(shouldMe){
        searchParamsTmp.shouldMe = shouldMe
      }else{
        for(let i in searchParamsTmp){
          if(i == 'shouldMe')delete searchParamsTmp[i]
        }
      }
      if(companyId.length)searchParamsTmp.corpId = companyId
      api.perfAdjList(searchParamsTmp).then(res => { 
        setCount(res.count)
        setList(res.list)
        setLoading(false)
      }).catch(() => {
        setLoading(false)
      })
      apiOperateTimer = false
      setShouldMeDis(false)
      let timer = setTimeout(() => {
        apiOperateTimer = true
        setShouldMeDis(true)
        clearTimeout(timer)
      }, 1000)
    }else{
      return message.info('请不要频繁操作')
    }
  }

  const onSetShouldMe = e => {
    if(e == true){
      shouldMe = 1
    }else{
      shouldMe = null
    }
    getPerfAdjList()
  }
 
  //获取筛选项
  const getListFilter = () => {
    api.perfAdjListFilter().then(res => { 
      setFilter(res)
    }).catch(() => {
    })
  }
  //获取部门列表
  const getDepartmentList = () => {
    commonalityDepartment()
  }

  //数据转换
  const transData = (vals) => {
    if(vals !== undefined){
      for(let i in vals){
        if(vals[i] !== undefined){
          if(i == 'applyTime'){
            searchParamsTmp.applySTime = moment(vals[i][0]).format('YYYY-MM-DD')
            searchParamsTmp.applyETime = moment(vals[i][1]).format('YYYY-MM-DD')
          }else if(i == 'auditTime'){
            searchParamsTmp.auditSTime = moment(vals[i][0]).format('YYYY-MM-DD')
            searchParamsTmp.auditETime = moment(vals[i][1]).format('YYYY-MM-DD') 
          }else{
            searchParamsTmp[i] = vals[i]
          }
        }
      }
    }
  }
  //点击筛选
  const searchSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      //console.log(vals)
      transData(vals)
      if (!err) {
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getPerfAdjList()
        setFilterName(undefined)
      }
    })
    
  }
  //选择保存方案
  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容
    val && api.getSearchStorage({key: location.pathname}).then(res => {
      setFilterContent(res.list)
    })
  }
  //选择方案
  const onFilterChecked = (item, val2) => {
    // console.log('item=>', item)
    setFilterName(item)
    setStatusValName(val2.props.children.props.children[0])
    // let data = JSON.parse(item)
    let data = filterContent[item].content
    searchParamsTmp = {...searchParamsTmp, ...data}
    // console.log(searchParamsTmp)
    setFieldsValue({
      staffNo: data.staffNo ? data.staffNo : undefined,
      department: data.department ? data.department : undefined,
      userName: data.userName ? data.userName : undefined,
      orderNo: data.orderNo ? data.orderNo : undefined,
      client: data.client ? data.client : undefined,
      clientId: data.clientId ? data.clientId : undefined,
      majorminor: data.majorminor ? data.majorminor : undefined,
      product: data.product ? data.product : undefined,
      business: data.business ? data.business : undefined,
      figtype: data.figtype ? data.figtype : undefined,
      auditStatus: data.auditStatus ? data.auditStatus : undefined,
      coltype: data.coltype ? data.coltype : undefined,
      coltypeprice: data.coltypeprice ? data.coltypeprice : undefined,
      creditNo: data.creditNo ? data.creditNo : undefined,
      orderType: data.orderType ? data.orderType : undefined,
      applySTime: data.applyTime ? [moment(data.applyTime[0]),moment(data.applyTime[1])] : undefined,
      applyETime: data.applyTime ? [moment(data.applyTime[0]),moment(data.applyTime[1])] : undefined, 
      auditSTime: data.auditTime ?  [moment(data.auditTime[0]), moment(data.auditTime[1])]: undefined,
      auditETime: data.auditTime ?  [moment(data.auditTime[0]), moment(data.auditTime[1])] : undefined
    })
    getDepartmentList()
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  //保存筛选条件
  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      //console.log(vals)
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item === '' || item !== undefined)) {
          storeFilterContent = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'applyTime' || k === 'auditTime') {
                storeFilterContent[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
              } else {
                storeFilterContent[k] = vals[k]
              }
            }
          }
          setFilterModelvisible(true)
        } else {
          message.error('请选择筛选内容')
        }
      }
    })
  }
  //保存筛选点击确定
  const filterHandleOk = () => {
    validateFields((err, vals) => {   
      api.saveSearchStorage({
        name: storeFilterName,
        key: location.pathname,
        content: storeFilterContent
      }).then(() =>{
        setFilterModelvisible(false)
        message.success('添加成功')
      })
    })
  }
  //取消筛选点击确定
  const filterHandleCancel = () => {
    setFilterModelvisible(false)
  }
  //修改筛选名称
  const onFilterInfoChange = (e) => {
    storeFilterName = e.target.value
  }

  const onPerfAdjDel = (id)=>{
    api.perfAdjDel({id: id}).then(res => {
      getPerfAdjList()
      message.info("删除成功")
    })
  }

  //导出
  const onExportFile = () => {
    setShowExport(true)
    allFilter = Object.assign({}, searchParamsTmp)
    for(let i in allFilter){
      if(i == 'page' || i == 'limit')delete allFilter[i]
    }
    if(allFilter.companyId)allFilter.companyId = allFilter.companyId + ''
    // console.log(allFilter)
  }

  return (
    <>
      <CorpFilter onChange={onChangeCorp} />
      <div className="search-askforleave-wrap">
        <h4 className="title">其它选项：</h4>
        {
          !showAll ? <span className="askforleave-filter-control" onClick={() => setShowAll(true)}>展开<Icon type="down" /></span>
          : <span className="askforleave-filter-control" onClick={() => setShowAll(false)}>收起<Icon type="up" /></span>
        }
        <Form onSubmit={searchSubmit} {...formItemLayout}>
          <div className={`form-box${showAll ? ' askforleave-form-auto' : ''}`}>
            <FormItem label="员工编号">
              {getFieldDecorator('staffNo')(<Input 
                placeholder="请输入员工编号" 
              />)}
            </FormItem>
            <FormItem label="公司用名">
              {getFieldDecorator('userName')(<Input 
                placeholder="请输入公司用名" 
              />)}
            </FormItem> 
            <FormItem label="部门">
              {getFieldDecorator('department')(<Select
                placeholder="全部"  
                onDropdownVisibleChange={bool => bool && getDepartmentList()}
                showSearch
                optionFilterProp="children"
                dropdownMatchSelectWidth={false}
              >
                {
                  departmentList.map(v => {
                    return !companyId.length ? <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option> : <Option title={v.name} key={v.id}>{v.name}</Option>
                  })
                }
              </Select>)}
            </FormItem> 
            <FormItem label="订单类型">
              {getFieldDecorator('orderType')(<Select
                placeholder="全部"
                dropdownMatchSelectWidth={false}
              >
                {
                  filter.orderType && filter.orderType.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="主项/副项">
              {getFieldDecorator('majorminor')(<Select
                placeholder="全部"
                dropdownMatchSelectWidth={false}
              >
                {
                  filter.major && filter.major.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="业绩类型">
              {getFieldDecorator('figtype')(<Select
                placeholder="全部" 
                dropdownMatchSelectWidth={false}
              >
                {
                  filter.figure && filter.figure.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem> 
            <FormItem label="申请日期">
              {getFieldDecorator('applyTime', {
              })(<RangePicker allowClear={false} />)}
            </FormItem> 
            <FormItem label="完成日期">
              {getFieldDecorator('auditTime', {
              })(<RangePicker allowClear={false} />)}
            </FormItem>
            <FormItem label="关联订单">
              {getFieldDecorator('orderNo')(<Input 
                placeholder="请输入关联订单" 
              />)}
            </FormItem> 
          </div>
          <FormItem className="btns" label=" " colon={false}>
            <div className="staff-filter-btns">
              {showAll && <>
                <h4 className="filter-title">已保存筛选方案：</h4>
                <div className="filter-select">
                  <Select style={{display: 'inline-block', width: '160px'}} placeholder="请选择" onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)} onSelect={onFilterChecked} value={filterName}> 
                    {filterContent.map((item, index) =>
                      <Option value={index} key={index}>
                        <div className="closeStyBtn">
                          {item.name}
                          {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                            e.stopPropagation()
                            onSearchDelete(item.id)
                          }} /></span> : null}
                        </div>
                      </Option>
                    )}                
                  </Select>
                </div>
                <Button className="filter-savebtn" onClick={onSaveFilterTerm}>保存筛选条件</Button>
              </>}
              <Switch 
            checkedChildren="待审批" 
            unCheckedChildren="待审批" 
            disabled={!shouldMeDis} 
            onClick={onSetShouldMe} 
            className="shouldme-switch-btn" 
            checked={shouldMe ? true : false} 
          />
              <Button onClick={onReset}>重置</Button>
              <Button type="primary" htmlType="submit">筛选</Button>
              <Modal
                title="保存筛选条件"
                visible={filterModelvisible}
                onOk={filterHandleOk}
                onCancel={filterHandleCancel}
              >
                <Input placeholder="请输入筛选名称" onChange={onFilterInfoChange}/>
              </Modal>
            </div>
          </FormItem>   
        </Form>
      </div>
      <div className="line"></div>
      <div className="white-line"></div>
      <div className="add-wrap">
        <Auth auths={authList} code="export">
          <Button type="primary" onClick={onExportFile} style={{ marginLeft: 15 }}>导出</Button>
        </Auth>
      </div>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl="getPerfAdjExportTpl"
        fieldsUrl="getPerfAdjTplItems"
        saveUrl="savePerfAdjExportTpls"
        exportUrl="exportPerfAdj"
        method="export"
        parame={{ ExportType: 'performanceadjust.export', id: selectedIds, ...allFilter }}
        cRef={productsRef}
      />
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        loading={loading}
        className="askforleave-table"
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable}
        scroll={{x: 'max-content'}}
      >
        <Column title="ID" dataIndex="id"  /> 
        <Column title="关联订单"  dataIndex="orderNo" render={(text, record) =>
          <>
            {
              <Link to={`/performance/adjustment/detail?id=${record.id}&new=1&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text}</Link>
            }
          </>
        } /> 
        <Column title="订单类型" dataIndex="orderTypeName" />
        <Column title="员工编号" dataIndex="staffNo" />
        <Column title="申请人" dataIndex="applyName" />
        <Column title="所属部门" dataIndex="department" />
        <Column title="职位" dataIndex="position" />
        <Column title="状态" dataIndex="auditStatusName" />
        <Column title="申请时间" dataIndex="addTime" />
        <Column title="完成时间" dataIndex="auditTime" />
        <Column title="操作" key="oprate" fixed="right" render={(text, record) => {
          return <>
            <Tooltip title="在新页面打开详情页" placement="bottom">
              <Link style={{marginRight: 5}} target='_blank' to={`/performance/adjustment/detail?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>新页面</Link>
            </Tooltip>
            {record.delFlag === true && <Popconfirm title="请确认是否删除，删除后不可恢复。" cancelText="取消" okText="确认" onConfirm={() => onPerfAdjDel(record.id)}>
              <Button type="danger" size="small">删除</Button>
            {' '}  
            </Popconfirm>}
            {record.editFlag === true && <Button type="primary" size="small" onClick={() => {
              history.push('/performance/adjustment/apply', { id: record.id})
            }}>编辑</Button>}
          </>
        }} />
      </Table> 
    </>
  )
}

export default Form.create()(Personal)