/**
 * 模块名称：申请业绩调整
 * @author zuoshen@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Adjustment from './Adjustment'
import Personal from './Personal'
import ProductSet from './ProductSet'
import NodeManage from './NodeManage'
import Detail from './module/Detail' 
import Apply from './module/Apply'
import DetailHistory from './module/DetailHistory'
import DetailList from './DetailListNew'
import ImportDetail from './ImportDetail'
import './assets/ProductSet.scss'

const Index = ({ match, history }) => {
   
  return (
    <Switch>
      <Route path={match.path + '/personal'} exact component={Personal} />
      <Route path={match.path + '/productSet'} exact component={ProductSet} />
      <Route path={match.path + '/nodeManage'} exact component={NodeManage} />
      <Route path={match.path + '/adjustment/apply'} exact component={Apply} />
      <Route path={match.path + '/adjustment/detail'} exact component={Detail} /> 
      <Route path={match.path + '/adjustment/history'} exact component={DetailHistory} />  
      <Route path={match.path + '/adjustment'} exact component={Adjustment} />  
      <Route path={match.path + '/detail'} exact component={DetailList} /> 
      <Route path={match.path + '/importDetail'} component={ImportDetail} /> 
      <Route component={Personal} />
    </Switch>
  )
}

export default Index