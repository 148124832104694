/**
 * 模块名称: 消耗导入详情
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import moment from 'moment'
import {
  Table,
  Form,
  Icon,
  Input,
  Button,
  Select,
  Modal,
  message,
  Alert,
  DatePicker,
  Spin
} from 'antd'
import CorpFilter from 'components/CorpFilter'
import { parseSearch } from 'utils'
import Export from 'components/Export'
import Auth from '@/components/AuthMiddleware'
import CryptoJS from 'crypto-js'
let selectedIds = ''
const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker, MonthPicker } = DatePicker

let pageSize = 10
let currentPage = 1
let companyId = []
let searchParamsTmp = {
  limit: pageSize,
  page: currentPage,
  companyId: companyId
}
let storeFilterContent = {}
let allFilter = {}

const ImportDetail = (props) => {
  const { history, match, location } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const productsRef = useRef()
  const [filterName, setFilterName] = useState(undefined)
  const [count, setCount] = useState(0)
  const [totalNum, setTotalNum] = useState('')
  const [list, setList] = useState([])
  const [filter, setFilter] = useState({})
  const [loading, setLoading] = useState(false)
  const [departmentList, setDepartmentList] = useState([])
  const [filterModelvisible, setFilterModelvisible] = useState(false)
  const [filterContent, setFilterContent] = useState([])
  const [showAll, setShowAll] = useState(false)
  const [showExport, setShowExport] = useState(false)
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)
  const [authList, setAuthList] = useState([])
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const search = parseSearch(location.search)
  const [checkedIdArr, setCheckedIdArr] = useState([])
  const [checked, setChecked] = useState(0)
  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage
    }
    storeFilterContent = {}
    getListFilter()
    getConsumptionGetList()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  //点击选择所属公司
  const onChangeCorp = (data) => {
    // console.log('onChangeCorp', data)
    setDepartmentList([])
    setFieldsValue({ 'staffDepartmentId': undefined })
    currentPage = 1
    history.replace(match.path)
    if (data.length) {
      let arr = []
      data.forEach((item, index) => {
        arr.push(item.id)
      })
      companyId = [].concat(arr)
      // searchParamsTmp = {
      //   limit: pageSize,
      //   page: currentPage,
      //   companyId: companyId
      // }
      searchParamsTmp.companyId = companyId
    } else {
      companyId = []
      searchParamsTmp = {
        limit: pageSize,
        page: currentPage
      }
    }
    getConsumptionGetList()
  }

  //点击重置
  const onReset = () => {
    currentPage = 1
    history.replace(match.path)
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage,
      companyId: companyId
    }
    resetFields()
    setFilterName(undefined)
    setStatusValName(undefined)
    getConsumptionGetList()
    setCheckedIdArr([])
    setChecked(0)
  }

  //分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getConsumptionGetList()
  }

  //获取列表
  const getConsumptionGetList = () => {
    setList([])
    setLoading(true)
    if (companyId.length) searchParamsTmp.companyId = companyId
    api.getConsumptionGetList(searchParamsTmp).then(res => {
      setCount(res.count)
      setTotalNum(res)
      setList(res.list)
      if (currentPage === 1) {
        if (res.ids) {
          localStorage.setItem('ids', JSON.stringify(res.ids))
        } else {
          localStorage.setItem('ids', JSON.stringify([]))
        }
      }
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }

  //获取筛选项
  const getListFilter = () => {
    api.getConsumptionFields().then(res => {
      setFilter(res)
    }).catch(() => {
    })
  }
  //获取部门列表
  const getDepartmentList = () => {
    api.getDepartment({ limit: global.paramsLimit, companyId }).then(res => {
      setDepartmentList(res.list)
    })
  }

  //数据转换
  const transData = (vals) => {
    if (vals !== undefined) {
      for (let i in vals) {
        if (vals[i] !== undefined) {
          if (i == 'addTime') {
            searchParamsTmp.createTimeStart = moment(vals[i][0]).format('YYYY-MM-DD')
            searchParamsTmp.createTimeEnd = moment(vals[i][1]).format('YYYY-MM-DD')
          } else if (i == 'month') {
            searchParamsTmp.month = moment(vals[i]).format('YYYY年MM月')
          } else {
            searchParamsTmp[i] = vals[i]
          }
        }
      }
      // console.log('searchParamsTmp=>', searchParamsTmp)
    }
  }
  //点击筛选
  const searchSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      //console.log(vals)
      transData(vals)
      if (!err) {
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getConsumptionGetList()
        setCheckedIdArr([])
        setChecked(0)
      }
    })

  }
  //选择保存方案
  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容
    val && api.getSearchStorage({ key: location.pathname }).then(res => {
      setFilterContent(res.list)
    })
  }
  //选择方案
  const onFilterChecked = (item, val2) => {
    // console.log('item=>', item)
    setFilterName(item)
    setStatusValName(val2.props.children.props.children[0])
    let data = filterContent[item].content
    searchParamsTmp = { ...searchParamsTmp, ...data }
    setFieldsValue({
      staffNumber: data.staffNumber ? data.staffNumber : undefined,
      staffName: data.staffName ? data.staffName : undefined,
      orderNumber: data.orderNumber ? data.orderNumber : undefined,
      vendor: data.vendor ? data.vendor : undefined,
      staffDepartmentId: data.staffDepartmentId ? data.staffDepartmentId : undefined,
      staffGroupId: data.staffGroupId ? data.staffGroupId : undefined,
      staffLeaderId: data.staffLeaderId ? data.staffLeaderId : undefined,
      staffIsOnJob: data.staffIsOnJob ? data.staffIsOnJob : undefined,
      extType: data.extType ? data.extType : undefined,
      majorType: data.majorType ? data.majorType : undefined,
      figureType: data.figureType ? data.figureType : undefined,
      perId: data.perId ? data.perId : undefined,
      clientId: data.clientId ? data.clientId : undefined,
      clientName: data.clientName ? data.clientName : undefined,
      figSource: data.figSource ? data.figSource : undefined,
      month: data.month ? moment(data.month) : undefined,
      staffJobPosition: data.staffJobPosition ? data.staffJobPosition : undefined,
      addTime: data.addTime ? [moment(data.addTime[0]), moment(data.addTime[1])] : undefined
    })
    getDepartmentList()
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  //保存筛选条件
  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      //console.log(vals)
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item === '' || item !== undefined)) {
          storeFilterContent = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'addTime') {
                storeFilterContent[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
              } else {
                storeFilterContent[k] = vals[k]
              }
            }
          }
          setFilterModelvisible(true)
        } else {
          message.error('请选择筛选内容')
        }
      }
    })
  }

  //保存筛选点击确定
  const filterHandleOk = () => {
    if (filterInfoValue.trim() === '') {
      message.error('筛选名称不能为空')
      return
    }
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: storeFilterContent
    }).then(() => {
      setFilterModelvisible(false)
      message.success('添加成功')
    })

  }
  //取消筛选点击确定
  const filterHandleCancel = () => {
    setFilterModelvisible(false)
  }
  //修改筛选名称
  const onFilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value)
  }

  //导出
  const onExportFile = () => {
    setShowExport(true)
    allFilter = Object.assign({}, searchParamsTmp)
    for (let i in allFilter) {
      if (i == 'page' || i == 'limit') delete allFilter[i]
    }
    if (allFilter.companyId && allFilter.companyId.length > 0) {
      if (!Array.isArray(allFilter.companyId)) {
        allFilter.companyId = allFilter.companyId.split(',')
      }
    } else {
      allFilter.companyId = []
    }
    // console.log(allFilter)
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //console.log(selectedRowKeys, selectedRows)
      setChecked(selectedRowKeys.length)
      selectedIds = selectedRowKeys
    },
    onSelect: (record, selected, selectedRows) => {
      // console.log(record, selected, selectedRows)
      if (!selected) {
        for (let i = 0; i < checkedIdArr.length; i++) {
          if (checkedIdArr[i] == record.id) {//eslint-disable-line
            checkedIdArr.splice(i, 1)
            i--
          }
        }
      } else {
        checkedIdArr.push(record.id)
      }
      let arrNew = [...new Set(checkedIdArr)]
      setCheckedIdArr(arrNew)
      setChecked(arrNew.length)
      selectedIds = arrNew
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
    //全选一列（默认十条）
    onSelectAll: (selected, selectedRows, changeRows) => {
      //console.log(selected, selectedRows, changeRows)
      let arr = []
      changeRows.forEach(item => {
        arr.push(item.id)
      })
      if (selected) {
        setCheckedIdArr([...new Set([...checkedIdArr, ...arr])])
      } else {
        setCheckedIdArr(checkedIdArr.filter(item => !arr.includes(item)))
      }
    },
    selectedRowKeys: checkedIdArr
  }

  //反选
  const onReverse = () => {
    setShowLoading(true)
    setTimeout(() => {
      let IDS = JSON.parse(localStorage.getItem('ids'))
      let arr = []
      for (let i = 0; i < IDS.length; i++) {
        arr[i] = IDS[i]
      }
      let filterIds = []
      arr.forEach(item => {
        if (!checkedIdArr.includes(item)) {
          filterIds.push(item)
        }
      })
      setCheckedIdArr(filterIds)
      setChecked(filterIds.length)
      selectedIds = filterIds
      setShowLoading(false)
    }, 500)
  }

  //全选
  const onAllelection = () => {
    let IDS = JSON.parse(localStorage.getItem('ids'))
    let arr = []
    for (let i = 0; i < IDS.length; i++) {
      arr[i] = IDS[i]
    }
    setChecked(arr.length)
    setCheckedIdArr(arr)
    //console.log(arr)
    selectedIds = arr
  }

  return (
    <Spin spinning={showLoading}>
      <CorpFilter onChange={onChangeCorp} />
      <div className="search-askforleave-wrap">
        <h4 className="title">其它选项：</h4>
        {
          !showAll ? <span className="askforleave-filter-control" onClick={() => setShowAll(true)}>展开<Icon type="down" /></span>
            : <span className="askforleave-filter-control" onClick={() => setShowAll(false)}>收起<Icon type="up" /></span>
        }
        <Form onSubmit={searchSubmit} {...formItemLayout}>
          <div className={`form-box${showAll ? ' askforleave-form-auto' : ''}`}>
            <FormItem label="员工编号">
              {getFieldDecorator('staffNumber')(<Input
                placeholder="请输入员工编号"
              />)}
            </FormItem>
            <FormItem label="公司用名">
              {getFieldDecorator('staffName')(<Input
                placeholder="请输入公司用名"
              />)}
            </FormItem>
            <FormItem label="关联订单">
              {getFieldDecorator('orderNumber')(<Input
                placeholder="请输入关联订单"
              />)}
            </FormItem>
            <FormItem label="厂商ID">
              {getFieldDecorator('vendor')(<Input
                placeholder="请输入厂商ID"
              />)}
            </FormItem>
            <FormItem label="部门">
              {getFieldDecorator('staffDepartmentId')(<Select
                placeholder="全部"
                onDropdownVisibleChange={bool => bool && getDepartmentList()}
                showSearch
                optionFilterProp="children"
                dropdownMatchSelectWidth={false}
              >
                {
                  departmentList.map(v => {
                    return !companyId.length ? <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option> : <Option title={v.name} key={v.id}>{v.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="事业群">
              {getFieldDecorator('staffGroupId')(<Select
                placeholder="全部"
                showSearch
                optionFilterProp="children"
                dropdownMatchSelectWidth={false}
              >
                {
                  filter.group && filter.group.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="直接主管">
              {getFieldDecorator('staffLeaderId')(<Input
                placeholder="请输入直接主管"
              />)}
            </FormItem>
            <FormItem label="员工状态">
              {getFieldDecorator('staffIsOnJob')(<Select
                placeholder="全部"
                showSearch
                optionFilterProp="children"
                dropdownMatchSelectWidth={false}
              >
                {
                  filter.staffStatus && filter.staffStatus.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="订单类型">
              {getFieldDecorator('extType')(<Select
                placeholder="全部"
                showSearch
                optionFilterProp="children"
                dropdownMatchSelectWidth={false}
              >
                {
                  filter.orderType && filter.orderType.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="主项/副项">
              {getFieldDecorator('majorType')(<Select
                placeholder="全部"
                showSearch
                optionFilterProp="children"
                dropdownMatchSelectWidth={false}
              >
                {
                  filter.major && filter.major.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="业绩类型">
              {getFieldDecorator('figureType')(<Select
                placeholder="全部"
                showSearch
                optionFilterProp="children"
                dropdownMatchSelectWidth={false}
              >
                {
                  filter.profitType && filter.profitType.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="关联订单业绩">
              {getFieldDecorator('perId')(<Input
                placeholder="请输入订单业绩ID"
              />)}
            </FormItem>
            <FormItem label="客户ID">
              {getFieldDecorator('clientId')(<Input
                placeholder="请输入客户OA ID"
              />)}
            </FormItem>
            <FormItem label="客户名称">
              {getFieldDecorator('clientName')(<Input
                placeholder="请输入客户名称"
              />)}
            </FormItem>
            <FormItem label="业绩来源">
              {getFieldDecorator('figSource')(<Select
                placeholder="全部"
                showSearch
                optionFilterProp="children"
                dropdownMatchSelectWidth={false}
              >
                {
                  filter.profitSource && filter.profitSource.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="消耗业绩月份">
              {getFieldDecorator('month')(
                <MonthPicker placeholder="请选择" allowClear={false} />
              )}
            </FormItem>
            <FormItem label="职位">
              {getFieldDecorator('staffJobPosition')(<Select
                placeholder="全部"
                showSearch
                optionFilterProp="children"
                dropdownMatchSelectWidth={false}
              >
                {
                  filter.position && filter.position.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="消耗业绩生成时间" style={{ width: '40%' }}>
              {getFieldDecorator('addTime', {
              })(<RangePicker allowClear={false} />)}
            </FormItem>
          </div>
          <FormItem className="btns" label=" " colon={false}>
            <div className="staff-filter-btns">
              {showAll && <>
                <h4 className="filter-title">已保存筛选方案：</h4>
                <div className="filter-select">
                  <Select style={{ display: 'inline-block', width: '160px' }} placeholder="请选择" onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)} onSelect={onFilterChecked} value={filterName}>
                    {filterContent.map((item, index) =>
                      <Option value={index} key={index}>
                        <div className="closeStyBtn">
                          {item.name}
                          {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                            e.stopPropagation()
                            onSearchDelete(item.id)
                          }} /></span> : null}
                        </div>
                      </Option>
                    )}
                  </Select>
                </div>
                <Button className="filter-savebtn" onClick={onSaveFilterTerm}>保存筛选条件</Button>
              </>}
              <Auth auths={authList} code="export">
                <Button type="primary" icon="upload" onClick={onExportFile} style={{ marginLeft: 15 }}>导出</Button>
              </Auth>
              <Button onClick={onReset}>重置</Button>
              <Button type="primary" htmlType="submit">筛选</Button>
              <Modal
                title="保存筛选条件"
                visible={filterModelvisible}
                onOk={filterHandleOk}
                onCancel={filterHandleCancel}
              >
                <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={onFilterInfoChange} />
              </Modal>
            </div>
          </FormItem>
        </Form>
      </div>
      <div className="line"></div>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl='getConsumptionChangeExportTpl'
        fieldsUrl='getConsumptionChangeTplItems'
        saveUrl='saveConsumptionChangeExportTpls'
        exportUrl='exportConsumptionChange'
        method="export"
        parame={{ ExportType: 'consumption.export', id: selectedIds, ...allFilter }}
        // type={1}
        cRef={productsRef}
      />
      <Alert style={{ marginTop: 18 }} message={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <span>已选择 <span style={{ color: '#168FFF' }}>{checked}</span> 项</span>
            {checked !== 0 &&
              <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={onReverse}>反选</span>}
            {checked === 0 && <span style={{ marginLeft: 10, color: '#ccc', cursor: 'default' }}>反选</span>}
            <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }}
              onClick={onAllelection}>全选</span>
          </div>
          <div>
            <span>{`数据总计: ${count}条数据`}</span> &nbsp;&nbsp;&nbsp;
            <span>{`合计消耗业绩: ${totalNum && totalNum.total_percon ? totalNum.total_percon : ''}`}</span> &nbsp;&nbsp;&nbsp;
            <span>{`新开消耗业绩: ${totalNum && totalNum.total_bill ? totalNum.total_bill : ''}`}</span> &nbsp;&nbsp;&nbsp;
            <span>{`续费消耗业绩: ${totalNum && totalNum.total_rebill ? totalNum.total_rebill : ''}`}</span> &nbsp;&nbsp;
          </div>
        </div>
      } type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        loading={loading}
        className="askforleave-table"
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable}
        scroll={{ x: 2000 }}
        rowSelection={rowSelection}
      >
        <Column title="客户ID" dataIndex="clientId" fixed="left" width={120} />
        <Column title="客户名称" dataIndex="clientName" fixed="left" width={180} />
        <Column title="厂商ID" dataIndex="cid" />
        <Column title="消耗时间" dataIndex="addtime" />
        <Column title="导入时间" dataIndex="createtime" />
        <Column title="赠送消耗" dataIndex="consumption_zeng" />
        <Column title="导入消耗" dataIndex="consumption" render={(text, record) =>
          <a onClick={() => {
            history.push(`/performance/importDetail/detailList`, {
              conId: record.id,
              from: 1
            })
          }}>{text}</a>
        } />
        <Column title="生成消耗业绩的消耗" dataIndex="stat_con" render={(text, record) =>
          <a onClick={() => {
            history.push(`/performance/importDetail/detailList`, {
              conId: record.id,
              from: 2
            })
          }}>{text}</a>
        } />
        <Column title="未生成消耗业绩的消耗" dataIndex="stat_unused" render={(text, record) =>
          <a onClick={() => {
            history.push(`/performance/importDetail/detailList`, {
              conId: record.id,
              from: 3
            })
          }}>{text}</a>
        } />
        <Column title="生成消耗业绩" dataIndex="stat_percon" />
        <Column title="新开消耗业绩" dataIndex="stat_bill" />
        <Column title="续费消耗业绩" dataIndex="stat_rebill" />
      </Table>
    </Spin>
  )
}

export default Form.create()(ImportDetail)
