/**
 * 模块名称：申请业绩调整
 * @author zuoshen@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
	Steps,
	Descriptions,
	Button,
	Modal,
	Input,
	Icon,
	message,
	Spin
} from 'antd'
import { parseSearch } from '@/utils'
import CryptoJS from 'crypto-js'

const { Step } = Steps
const dItem = Descriptions.item

let areaTxt = '', result = ''

const Detail = (props) => {

	const { history, location } = props

	const [detail, setDetail] = useState({})//全部数据 
	const [auditProgress, setAuditProgress] = useState([])//审核进程 
	const [orderInfo, setOrderInfo] = useState({})//订单信息 
	const [oldOrderInfo, setOldorderInfo] = useState([])//变更前订单信息
	const [aduitLog, setAduitLog] = useState([])//审核日志
	const [modalVisible, setModalVisible] = useState(false)
	const [pageId, setPageId] = useState('')
	const [loading, setLoading] = useState(true)
	const [resPlaceholder, setResPlaceholder] = useState('请输入至少1个字符') 

	const [perfDistribution, setPerfDistribution] = useState([])

	//操作权限
	const [auditAuth, setAuditAuth] = useState(false)//设置按钮组是否展示
	const [hasChargerPower, setHasChargerPower] = useState(false)//设置驳回和通过按钮是否展示（订单同时控制展示不展示手里提交和生效 以及 折扣节点，此4个按钮在它的基础上再根据currentStatus判断是否展示）
	const [replenish, setReplenish] = useState(false)//设置补充资料按钮是否展示
	const [returnUp, setReturnUp] = useState(false)//设置转上级按钮是否展示
	const [rejectMsgRequire, setRejectMsgRequire] = useState(false)//设置审批意见驳回必填 

	useEffect(() => {
		let plaintext = parseSearch(location.search).sign
		let plaintextTwo = CryptoJS.MD5(parseSearch(location.search).id + 'ad629fddf8b8756d2e72e96ae035a5a4' + parseSearch(location.search).time).toString()

		if (plaintext == plaintextTwo && +new Date() - +parseSearch(location.search).time <= 21600000) {
			areaTxt = ''
			result = ''
			if (location.search) {
				// console.log(parseSearch(location.search))
				setPageId(parseSearch(location.search).id)
				getPerformanceAdjDetail(parseSearch(location.search).id)
			} else {
				history.goBack()
			}
		} else {
			message.error('没有本页访问权限')
			setTimeout(() => {
				history.go(-2)
			}, 1000)
		}
	}, [])

	//获取详情
	const getPerformanceAdjDetail = (id) => {
		// id = 41 //假数据
		api.perfAdjDetail({ id: id, type: 0 }).then(res => {
			if (parseSearch(location.search).msgId) {
				api.setMessageReaded({ ids: [parseSearch(location.search).msgId] })
			}
			if (JSON.stringify(res) !== "[]") {
				let data = Object.assign({}, res)
				setDetail(data)
				setPerfDistribution(data.allotInfo)
				if (data) {
					setOrderInfo(data.orderInfo)
					setOldorderInfo(data.oldOrderInfo)
					setAduitLog(data.audit_log)
				}

				if (data.audit_flow) {
					let auditProgress = data.audit_flow
					auditProgress.forEach((item, index, self) => {
						if (item.persons && item.persons.length) {
							self[index].persons = item.persons.join('，')
						}
					})
					setAuditProgress(auditProgress)
				}

				if (data.auditAuth && JSON.stringify(data.auditAuth) !== '[]') {
					setAuditAuth(true)
					setHasChargerPower(data.auditAuth.hasChargerPower)
					setReplenish(data.auditAuth.replenish)
					setReturnUp(data.auditAuth.return_up)
					setRejectMsgRequire(data.auditAuth.reject_msg_require)
				} else {
					setAuditAuth(false)
					setHasChargerPower(false)
					setReplenish(false)
					setReturnUp(false)
					setRejectMsgRequire(false)
				}
			}
			setLoading(false)
		})
	}


	//点击好几个审批的按钮
	const leaveOutAudit = (no) => {

		areaTxt = ''
		result = no
		//通过
		if (no == 0) {//eslint-disable-line
			setResPlaceholder('请输入审批意见')
			setModalVisible(true)
			//驳回
		} else if (no == 1) {//eslint-disable-line
			//审批意见是否为必填项改由审批流控制 2020年2月22日 17:19:23
			setResPlaceholder('请输入审批意见')
			setModalVisible(true)
		} else {
			api.perfAdjAudit({
				id: pageId,
				type: result,
				remark: areaTxt,
			}).then(() => {
				goAuduitList()
			})
		}
	}
	//跳转审批列表页
	const goAuduitList = () => {
		let timer = setTimeout(() => {
			history.push({
				pathname: '/performance/personal'
			})
			clearTimeout(timer)
		}, 1000)
	}
	//通过和驳回
	const oprateHandleOk = () => {
		if (areaTxt.length < 1 && result == 1 && rejectMsgRequire) {//eslint-disable-line
			return message.warning('请输入至少1个字符')
		} 
		
		let params = {
			id: pageId,
			type: result,
			remark: areaTxt,
		}
		api.perfAdjAudit(params).then(res => {
			message.success('审批成功')
			setModalVisible(false)
			getPerformanceAdjDetail(pageId)
		})
	}
	//同意的样式
	const agreeNode = () => {
		return result === 0 ? <>
			同意 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
		</> : <>
				不同意 <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" />
			</>
	}
	//审批意见
	const getAreaTxt = (e) => {
		areaTxt = e.target.value.trim()
	}

	//退回列表页
	const onGoBack = () => {
		history.push({pathname:'/performance/adjustment',state: { goBack: 1}})
	}

	return (
		<Spin spinning={loading}>
			{
				parseSearch(location.search).new && parseSearch(location.search).new == 1 && <Button onClick={onGoBack} type="primary" style={{
					position: 'absolute',
					right: '20px',
					top: '20px'
				}}>返回上一页</Button>
			}
			<div className="add-orderDirect-detail">
				<Descriptions title={`业绩调整 订单编号: ${orderInfo.number || ''}`} layout="vertical" className="pro-detail-title" column={5} style={{ borderBottom: "none" }}>
					<dItem label="客户名称">{orderInfo.client}</dItem>
					<dItem label="OAID">{orderInfo.clientId}</dItem>
					<dItem label="厂商ID">{orderInfo.vendor}</dItem>
					{orderInfo.type == 0 && <dItem label="所属销售">{orderInfo.relSalerName}</dItem>}
					{orderInfo.type == 0 && <dItem label="所属客服">{orderInfo.relServicerName}</dItem>}
					{orderInfo.type == 1 && <dItem label="供应商所属开发">{orderInfo.relAgentStaffName}</dItem>}
					{orderInfo.type == 1 && <dItem label="提单人">{orderInfo.userName}</dItem>}
				</Descriptions>
				<div className="blank-line"></div>
				<div className="step-wrap">
					<div className="record-list-title">当前审批进度</div>
					<Steps current={detail.current}>
						{
							auditProgress.map((item, index, self) => {
								let status = 'wait'
								if (item.time && item.time.length > 1) {
									status = 'process'
								}
								// if (item.nodeStatus == 2) {
								//   status = 'finish'
								// }
								return <Step status={status} title={item.nodeName} key={index} description={
									<>
										{item.persons && <div title={item.persons}>{item.persons}</div>}
										<div title={item.statusName}>{item.statusName}</div>
										<div title={item.time}>{item.time}</div>
									</>
								} />
							})
						}
					</Steps>
				</div>
				<div className="blank-line"></div>
				<div className="ask-info">
					<div className="record-list-title">订单信息</div>
						<Descriptions layout="vertical" className="pro-detail-title" column={3} style={{ borderBottom: "none" }}>
							<dItem label="项目">{orderInfo.projectName}</dItem>
							<dItem label="产品">{orderInfo.productName}</dItem>
							<dItem label="业务类型">{orderInfo.businessName}</dItem>
							<dItem label="业绩类型">{orderInfo.figtypeName}</dItem>
							{orderInfo.type == '0' && <dItem label="是否撤资重开订单">{orderInfo.isodfdivestName}</dItem>}
							{orderInfo.type == '0' && <dItem label="是否退转订单">{orderInfo.isodftrunName}</dItem>}
							{orderInfo.type == '0' && <dItem label="是否框架订单">{orderInfo.isodfkjiaName}</dItem>}
							{orderInfo.type == '0' && <dItem label="销售主项">{orderInfo.majorminorName}</dItem>}
							{orderInfo.type == '1' && <dItem label="代理商名称">
								<div>{orderInfo.agent}</div>
								<div>ID: {orderInfo.agentId}</div>
							</dItem>}
						</Descriptions>
				</div>
				<div className="blank-line"></div>
				<div className="ask-info">
					<div className="record-list-title">订单收款信息
               <div style={{ float: 'right', fontWeight: 'normal', fontSize: '14px' }}>
							<a target="_blank" onClick = {()=>{
								history.push('/performance/adjustment/history',{orderId:orderInfo.id,id:pageId})
							}}>查看历史记录</a>
						</div>
					</div>
					<Descriptions layout="vertical" className="pro-detail-title" column={3} style={{ borderBottom: "none" }}>
						<dItem label="市场价">{orderInfo.colmarketprice}</dItem>
						<dItem label="实收">{orderInfo.colactualprice}</dItem>
						<dItem label="折扣">{orderInfo.colrebate}</dItem>
						<dItem label="个人利润">
							{
								oldOrderInfo.length === 0 ? orderInfo.colprofit :
									<div style={{ display: 'flex' }}>
										<div>
											<div>变更前：</div>
											<div>变更后：</div>
										</div>
										<div>
											<div style={{ textAlign: 'right' }}>{oldOrderInfo.colprofit ? oldOrderInfo.colprofit : <div>&nbsp;</div>}</div>
											<div style={{ textAlign: 'right', color: oldOrderInfo.colprofit !== detail.colProfit ? 'red' : '' }}>{detail.colProfit}</div>
										</div>
									</div>
							}
						</dItem>
						<dItem label="成本">
							{
								oldOrderInfo.length === 0 ? orderInfo.colcost :
									<div style={{ display: 'flex' }}>
										<div>
											<div>变更前：</div>
											<div>变更后：</div>
										</div>
										<div>
											<div style={{ textAlign: 'right' }}>{oldOrderInfo.colcost ? oldOrderInfo.colcost : <div>&nbsp;</div>}</div>
											<div style={{ textAlign: 'right', color: oldOrderInfo.colcost !== detail.colCost ? 'red' : '' }}>{detail.colCost}</div>
										</div>
									</div>
							}
						</dItem>
						<dItem label="调整项">
							{
								oldOrderInfo.length === 0 ? orderInfo.colrefund :
									<div style={{ display: 'flex' }}>
										<div>
											<div>变更前：</div>
											<div>变更后：</div>
										</div>
										<div>
											<div style={{ textAlign: 'right' }}>{oldOrderInfo.colrefund ? oldOrderInfo.colrefund : <div>&nbsp;</div>}</div>
											<div style={{ textAlign: 'right', color: oldOrderInfo.colrefund !== detail.colRefund ? 'red' : '' }}>{detail.colRefund}</div>
										</div>
									</div>
							}
						</dItem>
						<dItem label="提单人">{orderInfo.userName}</dItem>
						<dItem label="直接主管">{orderInfo.userLeaderName}</dItem>
						<dItem label="部门">{orderInfo.userDeptName}</dItem>
					</Descriptions>
				</div>
				<div className="blank-line"></div>
				<div className="ask-info">
					<div className="record-list-title">业绩分配信息</div>
					{perfDistribution.map((item,index) => {
						return (
							<Descriptions layout="vertical" key={item+index} column={4} className="pro-detail-title" style={{ borderBottom: "none" }}>
								<dItem label="业绩">
									{
										// item.oldCost !== '' ?
										<div style={{ display: 'flex' }}>
											<div>
												<div>变更前：</div>
												<div>变更后：</div>
											</div>
											<div>
												<div style={{ textAlign: 'right' }}>{item.oldCost ? item.oldCost : <div>&nbsp;</div>}</div>
												<div style={{ textAlign: 'right', color: item.oldCost !== item.cost ? 'red' : ''  }}>{item.cost}</div>
											</div>
										</div>
										// : item.cost
									}
								</dItem>
								<dItem label="分成人">{item.userName}</dItem>
								<dItem label="直接主管">{item.leader}</dItem>
								<dItem label="部门">{item.department}</dItem>
							</Descriptions>
						)
					})}
					<Descriptions layout="vertical" column={4} className="pro-detail-title" style={{ borderBottom: "none" }}>
						<dItem label='备注'>
							{detail.remark}
						</dItem>
					</Descriptions>
				</div>
				<div className="blank-line"></div>
				<div className="record-list">
					<div className="record-list-title">审批记录</div>
					{aduitLog.length > 0 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none", marginBottom: "-30px" }}>
						<dItem label="姓名"></dItem>
						<dItem label="时间"></dItem>
						<dItem label="状态"></dItem>
						<dItem label="审批意见"></dItem>
					</Descriptions>}
					{
						aduitLog.length > 0 ? aduitLog.map((item, index) => {
							return <Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{ borderBottom: "none", marginBottom: "-10px" }}>
								<dItem label="">{item.userInfo.staffName}</dItem>
								<dItem label="">{item.addTime}</dItem>
								<dItem label="">{item.status}</dItem>
								<dItem label="">{item.chargeMsg}</dItem>
							</Descriptions>
						}) : '-'
					}
				</div>

				{
					auditAuth && <div className="btns">
						{replenish && <Button onClick={() => leaveOutAudit(3)} className="orange-btn">补充资料</Button>}
						{returnUp && <Button onClick={() => leaveOutAudit(2)} className="orange-btn">转上级</Button>}
						{hasChargerPower && <Button onClick={() => leaveOutAudit(1)}>驳回</Button>}
						{hasChargerPower && <Button onClick={() => leaveOutAudit(0)} type="primary">通过</Button>}
					</div>
				}
				<Modal
					title={agreeNode()}
					visible={modalVisible}
					onOk={oprateHandleOk}
					onCancel={() => setModalVisible(false)}
					destroyOnClose={true}
				>
					<div className="officework-modal-content">
						<span className="label">意见：</span><Input.TextArea className="content-area" placeholder={resPlaceholder} onChange={getAreaTxt} rows={4} style={{ width: "100%" }} maxLength={100} />
					</div>
				</Modal>
			</div>
		</Spin>
	)
}

export default Detail