/**
 * 模块名称: 直销订单详情
 * @author zuoshen@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
	Steps,
	Descriptions,
	Button,
	Modal,
	Input,
	Icon,
	message,
  Spin,
  Table
} from 'antd'
import { parseSearch } from '@/utils'

const { Step } = Steps
const dItem = Descriptions.item
const { Column } = Table

let areaTxt = '', result = ''

const PayBackDetail = (props) => {

	const { history, location } = props

  const [detail, setDetail] = useState({})//全部数据
  const [base, setBase] = useState({})//基础信息
	const [auditProgress, setAuditProgress] = useState([])//审核进程
	const [contractInfo, setContractInfo] = useState({})//合同信息
	const [contactInfo, setContactInfo] = useState({})//联系人信息
	const [orderInfo, setOrderInfo] = useState({})//订单信息
	const [attachdata, setAttachdata] = useState([])//订单扩展资料字段信息
	const [orderExInfo, setOrderExInfo] = useState({})//订单资料
	const [colInfo, setColInfo] = useState({})//收款信息
	const [vendor, setVendor] = useState({})//厂商ID信息
	const [attchments, setAttchments] = useState([])//附件信息
	const [discountInfo, setDiscountInfo] = useState({})//折扣信息
	const [aduitLog, setAduitLog] = useState([])//审核日志
	const [modalVisible, setModalVisible] = useState(false)
	const [pageId, setPageId] = useState('')
	const [loading, setLoading] = useState(true)
  const [resPlaceholder, setResPlaceholder] = useState('请输入至少1个字符')
  
  //操作权限
  const [auditAuth, setAuditAuth] = useState(false)//设置按钮组是否展示
  const [hasChargerPower, setHasChargerPower] = useState(false)//设置驳回和通过按钮是否展示
  const [replenish, setReplenish] = useState(false)//设置补充资料按钮是否展示
  const [returnUp, setReturnUp] = useState(false)//设置转上级按钮是否展示
  const [rejectMsgRequire, setRejectMsgRequire] = useState(false)//设置审批意见驳回必填

	useEffect(() => {
		areaTxt = ''
		result = ''
		if(location.search){
      // console.log(parseSearch(location.search))
			setPageId(parseSearch(location.search).id) 
			beforePayBackApplie(41)
		}else{
			// history.push({
			// 	pathname: '/csr/customer'
			// })
		}  
		beforePayBackApplie(41);
	},[]) 
	//前置
	const beforePayBackApplie = (ids) =>{
		api.beforePayBackApplie({ids:41,type:0}).then(res=>{
      // console.log(res)
		})
	}
	//获取详情
	/*const getFortuneOrderDirectDetail = (id) => {		
		api.getFortuneOrderDirectDetail({id: id, type: 0}).then(res => {
			if (parseSearch(location.search).msgId) {
				api.setMessageReaded({ ids: [parseSearch(location.search).msgId] })
			}
			if(JSON.stringify(res) !== "[]"){			
				let data = Object.assign({}, res)
				if(data.nodeStatus === 0)data.current = 0
				if(data.nodeStatus === 1)data.current = 1
				if(data.nodeStatus === 2)data.current = 2
				if(data.nodeStatus === 3)data.current = 3
        setDetail(data)
        data.base && setBase(data.base)
        data.auditProgress && setAuditProgress(data.auditProgress)
        data.contractInfo && setContractInfo(data.contractInfo)
        data.contactInfo && setContactInfo(data.contactInfo)
        data.orderInfo && setOrderInfo(data.orderInfo)
        if(data.orderExInfo){
          setOrderExInfo(data.orderExInfo)
          if(data.orderExInfo.attachdata && data.orderExInfo.attachdata.length){
            data.orderExInfo.attachdata.forEach((item, index, self) => {
              self[index] = {
                name: Object.keys(item)[0],
                value: Object.values(item)[0]
              }
            })
            setAttachdata(data.orderExInfo.attachdata)
          }
        }
        data.colInfo && setColInfo(data.colInfo)
        data.vendor && setVendor(data.vendor)
        data.attchments && setAttchments(data.attchments)
        data.discountInfo && setDiscountInfo(data.discountInfo)
        data.aduitLog && setAduitLog(data.aduitLog)

        let auditProgress = data.auditProgress
        auditProgress.forEach((item, index, self) => {
          if(item.persons && item.persons.length){
            self[index].persons = item.persons.join('，')
          }
        })
				setAuditProgress(auditProgress)
        if(data.buttonAuthList && JSON.stringify(data.buttonAuthList) !== '[]'){
          setAuditAuth(true)
          setHasChargerPower(data.buttonAuthList.hasChargerPower)
          setReplenish(data.buttonAuthList.replenish)
					setReturnUp(data.buttonAuthList.return_up)
					setRejectMsgRequire(data.buttonAuthList.reject_msg_require)
        }else{
          setAuditAuth(false)
          setHasChargerPower(false)
          setReplenish(false)
					setReturnUp(false)
					setRejectMsgRequire(false)
				}
			}else{
				setDetail({})
        setBase({})
        setAuditProgress([])
        setContractInfo({})
        setContactInfo({})
        setOrderInfo({})
        setAttachdata([])
        setOrderExInfo({})
        setColInfo({})
        setVendor({})
        setAttchments([])
        setDiscountInfo({})
        setAduitLog([])
			}
			setLoading(false)
		})
	}*/
	//点击4个审批的按钮
	const leaveOutAudit = (no) => {
    areaTxt = ''
    result = no
    if(no == 0){//eslint-disable-line
		  setResPlaceholder('请输入审批意见')
      setModalVisible(true)
    }else if(no == 1){//eslint-disable-line
			//审批意见是否为必填项改由审批流控制 2020年2月22日 17:19:23
		  setResPlaceholder('请输入审批意见')
      setModalVisible(true)
    }else{
      api.onLeaveOutAudit({
        id: pageId,
        type: result,
        remark: areaTxt
      }).then(res => {
        //todo 转上级或者补充资料 返回列表页
        let timer = setTimeout(() => {
          history.push({
            pathname: '/attendance/askforleave'
          })
          clearTimeout(timer)
        }, 1000) 
      })           
    }
	}
	const oprateHandleOk = () => {
		if(areaTxt.length < 1 && result == 1 && rejectMsgRequire){//eslint-disable-line
			message.warning('请输入至少1个字符')
			return
		}
		let params = {
			id: pageId,
			type: result,
			remark: areaTxt
		}
		api.onLeaveOutAudit(params).then(res => {
			message.success('审批成功')
			setModalVisible(false)
			//PayBackDetail(pageId)
		})
	}
	//取消弹窗
	const oprateHandleCancel = () => {
		setModalVisible(false)	
	}
	//同意的样式
	const agreeNode = () => {
		return result === 0 ? <>
			同意 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
		</> : <>
			不同意 <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" />
		</>
	}
	//审批意见
	const getAreaTxt = (e) => {
		areaTxt = e.target.value.trim()
	}
	return (
		<Spin spinning={loading}>
			<div className="add-newask-detail">
        <Descriptions title={`直销订单-${base.coltype}`} layout="vertical" className="pro-detail-title" column={5} style={{borderBottom: "none"}}>
          <dItem label="客户名称">{vendor.companyName}</dItem>
          <dItem label="厂商ID">{vendor.vendor}</dItem>
          <dItem label="所属销售">{vendor.relSalerName}</dItem>
          <dItem label="所属客服">{vendor.relServicerName}</dItem>
        </Descriptions>
        <div className="blank-line"></div>
				<div className="step-wrap">
          <div className="record-list-title">当前审批进度</div>
          <Steps current={detail.current}>
          {
            auditProgress.map((item, index, self) => {
							let status = 'wait'
                if (item.time && item.time.length > 1) {
                  status = 'process'
                }
              return <Step status={status} title={item.nodeName} key={index} description={
                <>
                  {item.persons && <div title={item.persons}>{item.persons}</div>}
                  <div title={item.statusName}>{item.statusName}</div>
                  <div title={item.time}>{item.time}</div>
                </>
              } />
            })
          }
          </Steps>					
				</div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">合同信息</div>
					{contractInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={3} style={{borderBottom: "none"}}>
						<dItem label="客户姓名">{contractInfo.customerName}</dItem>
						<dItem label="合同编号">{contractInfo.contractNo}</dItem>
						<dItem label="合同名称">{contractInfo.templateName}</dItem>
						<dItem label="合同主体">{contractInfo.accountName}</dItem>
						<dItem label="合同有效期">{contractInfo.term}</dItem>
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单联系人</div>
					{contactInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={3} style={{borderBottom: "none"}}>
						<dItem label="订单联系人">{contactInfo.contactname}</dItem>
						<dItem label="手机">{contactInfo.contactmobile}</dItem>
						<dItem label="座机">{contactInfo.contacttel}</dItem>
						<dItem label="传真">{contactInfo.contactfax}</dItem>
						<dItem label="邮箱">{contactInfo.contactemail}</dItem>
						<dItem label="QQ">{contactInfo.contactqq}</dItem>
						<dItem label="网址">{contactInfo.contactweb}</dItem>
						<dItem label="通信地址">{contactInfo.contactaddress}</dItem>
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单信息</div>
					{orderInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={3} style={{borderBottom: "none"}}>
						<dItem label="项目">{orderInfo.projectName}</dItem>
						<dItem label="产品">{orderInfo.productName}</dItem>
						<dItem label="业务类型">{orderInfo.businessName}</dItem>
						<dItem label="业绩类型">{orderInfo.figtype}</dItem>
						<dItem label="是否撤资重开订单">{orderInfo.isodfdivest}</dItem>
						<dItem label="是否退转订单">{orderInfo.isodftrun}</dItem>
						<dItem label="是否框架订单">{orderInfo.isodfkjia}</dItem>
						<dItem label="销售主项">{orderInfo.majorminor}</dItem>
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单资料</div>
					{orderExInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={3} style={{borderBottom: "none"}}>
						<dItem label="公司名称">{orderExInfo.companyName}</dItem>
						<dItem label="一级行业">{orderExInfo.industry1}</dItem>
						<dItem label="二级行业">{orderExInfo.industry2}</dItem>
						<dItem label="供应商">{orderExInfo.supplierName}</dItem>
            {
              attachdata.map(((item, index) => <dItem label={item.name} key={+index+4}>{item.value}</dItem>))
            }
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单收款信息-{base.coltype}</div>
					{colInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={3} style={{borderBottom: "none"}}>
						<dItem label="市场价">{colInfo.colmarketprice}</dItem>
						<dItem label="实收">{colInfo.colactualprice}</dItem>
						<dItem label="折扣">{colInfo.colrebate}</dItem>
						<dItem label="个人利润">{colInfo.colprofit}</dItem>
						{colInfo.creditAccount && <dItem label="信用账户ID">{colInfo.creditAccount}（{colInfo.creditType}）</dItem>}
						<dItem label="成本">{colInfo.colcost}</dItem>
						<dItem label="调整项">{colInfo.colrefund}</dItem>
						<dItem label="提单人">{colInfo.userName}</dItem>
						<dItem label="直接主管">{colInfo.userLeader}</dItem>
						<dItem label="部门">{colInfo.userDepart}</dItem>
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单附件</div>
          <Descriptions title=""
            layout="horizontal"
            className="pro-detail-smailtitle"
          >
            <div className="content" style={{display: 'flex'}}>
              {
                attchments.length ? attchments.map((item, index) => {
                  return <div key={index}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
                }) : '无'
              }
            </div>
          </Descriptions>
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
					<Descriptions title="备注" layout="horizontal" className="pro-detail-smailtitle">
            <div className="content">
            {(vendor.remark && vendor.remark.length) ? vendor.remark : '-'}
            </div>
					</Descriptions> 
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">折扣信息</div>
					{discountInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={3} style={{borderBottom: "none"}}>
						<dItem label="客户名称">{discountInfo.clientName}</dItem>
            <dItem label="项目">{discountInfo.projectName}</dItem>
            <dItem label="产品">{discountInfo.productName}</dItem>
            <dItem label="一级行业">{discountInfo.industry1}</dItem>
            <dItem label="二级行业">{discountInfo.industry2}</dItem>
            <dItem label="折扣">{discountInfo.colrebate}</dItem>
            <dItem label="供应商">{discountInfo.supplier}</dItem>
            <dItem label="折扣类型">{discountInfo.discountType}</dItem>
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
				<div className="record-list">
          <div className="record-list-title">审批记录</div>
          {aduitLog.length > 0 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none", marginBottom: "-30px"}}>
						<dItem label="姓名"></dItem>
						<dItem label="时间"></dItem>
						<dItem label="状态"></dItem>
						<dItem label="审批意见"></dItem>
					</Descriptions>}
					{
						aduitLog.length > 0 ? aduitLog.map((item, index) => {
							return <Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{borderBottom: "none", marginBottom: "-10px"}}>
								<dItem label="">{item.userInfo.staffName}</dItem>
								<dItem label="">{item.addTime}</dItem>
								<dItem label="">{item.status}</dItem>
								<dItem label="">{item.chargeMsg}</dItem>
							</Descriptions>
						}) : '-'
					}
				</div>
				{
					auditAuth && <div className="btns">
            {replenish && <Button onClick={() => leaveOutAudit(3)} className="orange-btn">补充资料</Button>}
            {returnUp && <Button onClick={() => leaveOutAudit(2)} className="orange-btn">转上级</Button>}
						{hasChargerPower && <Button onClick={() => leaveOutAudit(1)}>驳回</Button>}
						{hasChargerPower && <Button onClick={() => leaveOutAudit(0)} type="primary">通过</Button>}
					</div>
				}
				<Modal
					title={agreeNode()}
					visible={modalVisible}
					onOk={oprateHandleOk}
					onCancel={oprateHandleCancel}
					destroyOnClose={true}
				>
					<div className="officework-modal-content">
						<span className="label">意见：</span><Input.TextArea className="content-area" placeholder={resPlaceholder} onChange={getAreaTxt} rows={4} style={{width: "100%"}} maxLength={100} />
					</div>
				</Modal>
			</div>
		</Spin>
	)
}

export default PayBackDetail