/**
 * 模块名称: 消耗导入详情
 * @author zhuyan@372163.com
 */

 import React from 'react'
 import { Switch, Route } from 'react-router-dom'
 import ImportDetail from './ImportDetail'
 import FromDetailList from './FromDetailList'

 const ImportDetailIndex = ({ match, history }) => {
 
   return (
     <Switch> 
       <Route path={match.path + '/detailList'} component={FromDetailList} />
       <Route component={ImportDetail} />
     </Switch>
   )
 }
 
 export default ImportDetailIndex