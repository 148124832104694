/**
 * 模块名称：业绩调整历史详情
 * @author zuoshen@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Row,
  Col,
  Descriptions,
  Button,
  Spin
} from 'antd'

const dItem = Descriptions.item

let areaTxt = '', result = ''

const DetailHistory = (props) => {

  const { history, location } = props

  const [orderInfo, setOrderInfo] = useState([])//收款信息
  const [historyInfo,setHistoryInfo] = useState([])
  const [loading, setLoading] = useState(true) 
 
  useEffect(() => {
    areaTxt = ''
    result = ''
    if (location.state) {
      getPerformanceAdjDetailHistory(location.state.orderId,location.state.id)
    } else {
      history.push({
        pathname: `/performance/personal`,

      })
    }
  }, []) 

  //获取历史详情
  const getPerformanceAdjDetailHistory = (orderId,id) => { 
    api.perfAdjHistory({ orderId: orderId,id:id }).then(res => { 
      if (JSON.stringify(res) !== "[]") {
        let data = Object.assign({}, res) 
				if (data) {
          setOrderInfo(data.orderInfo) 
          setHistoryInfo(data.history)
				} 
      }  
      setLoading(false)
    })
  }
  

  return (
    <Spin spinning={loading}>
      <div className="add-orderDirect-detail"> 
        <Descriptions title={`业绩调整 订单编号: ${orderInfo.number || ''}`} layout="vertical" className="pro-detail-title" column={5} style={{ borderBottom: "none" }}>
          <dItem label="客户名称">{orderInfo.client}</dItem>
          <dItem label="OAID">{orderInfo.clientId}</dItem>
          <dItem label="厂商ID">{orderInfo.vendor}</dItem>
          {orderInfo.type == 0 && <dItem label="所属销售">{orderInfo.relSalerName}</dItem>}
					{orderInfo.type == 0 && <dItem label="所属客服">{orderInfo.relServicerName}</dItem>}
					{orderInfo.type == 1 && <dItem label="供应商所属开发">{orderInfo.relAgentStaffName}</dItem>}
					{orderInfo.type == 1 && <dItem label="提单人">{orderInfo.userName}</dItem>}
        </Descriptions>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单信息</div>
          {orderInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={3} style={{ borderBottom: "none" }}>
            <dItem label="项目">{orderInfo.projectName}</dItem>
            <dItem label="产品">{orderInfo.productName}</dItem>
            <dItem label="业务类型">{orderInfo.businessName}</dItem>
            <dItem label="业绩类型">{orderInfo.figtypeName}</dItem>
            {orderInfo.type == '0' && <dItem label="是否撤资重开订单">{orderInfo.isodfdivestName}</dItem>}
							{orderInfo.type == '0' && <dItem label="是否退转订单">{orderInfo.isodftrunName}</dItem>}
							{orderInfo.type == '0' && <dItem label="是否框架订单">{orderInfo.isodfkjiaName}</dItem>}
							{orderInfo.type == '0' && <dItem label="销售主项">{orderInfo.majorminorName}</dItem>}
							{orderInfo.type == '1' && <dItem label="代理商名称">
              <div>{orderInfo.agent}</div>
              <div>ID: {orderInfo.agentId}</div>
            </dItem>}
          </Descriptions> : '-'}
        </div> 
        <div className="blank-line"></div>
        {historyInfo.map((item,index) => {
          return (<div key={index}>
            <div className="ask-info"  >
              <div className="record-list-title">订单收款信息-{item.affectDate}</div>
              <Descriptions layout="vertical" className="pro-detail-title"column={3} style={{ borderBottom: "none" }}>
                <dItem label="市场价">{item.colmarketprice}</dItem>
                <dItem label="实收">{item.colactualprice}</dItem>
                <dItem label="折扣">{item.colrebate}</dItem>
                <dItem label="个人利润">{item.colProfit}</dItem>
                <dItem label="成本">{item.colCost}</dItem>
                <dItem label="调整项">{item.colRefund}</dItem>
                <dItem label="提单人">{item.userName}</dItem>
                <dItem label="直接主管">{item.userLeaderName}</dItem>
                <dItem label="部门">{item.userDeptName}</dItem>
              </Descriptions> 
            </div>
            <div className="blank-line"></div>
            <div className="ask-info"  key={index+'a1'}>
              <div className="record-list-title">业绩分配信息-{item.auditTime}</div>
              {item.allotInfo.map((perf,key) => {
                return (
                  <Descriptions layout="vertical" key={key} column={4} className="pro-detail-title" style={{ borderBottom: "none" }}>
                    <dItem label="业绩">{perf.cost}</dItem>
                    <dItem label="分成人">{perf.userName}</dItem>
                    <dItem label="直接主管">{perf.leader}</dItem>
                    <dItem label="部门">{perf.department}</dItem>
                  </Descriptions>
                )
              })}
              <Descriptions layout="vertical" column={4} className="pro-detail-title" style={{ borderBottom: "none" }}>
                <dItem label='备注'>
                  {item.remark}
                </dItem>
              </Descriptions>
            </div>
            <div className="blank-line"></div>
          </div>
          )
        })}
      </div>
      <Row type="flex" justify="center" align="middle">
      <Col>
          <div className="btns" style={{paddingTop: 20}}><Button type="primary" onClick={()=>{history.go(-1)}}>返回</Button></div>
      </Col>
      </Row>
     
    </Spin>
  )
}

export default DetailHistory