/**
 * 模块名称: 消耗业绩产品配置 -> 新增
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Form,
  Select,
  message,
  InputNumber,
  Modal
} from 'antd'
import BtnGroup from '@/components/BtnGroup'

const FormItem = Form.Item
const Option = Select.Option
const { confirm } = Modal

const AddProductSet = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  const { seteditVisible, getPerformanceProductConfigList, setformLoading, addType, editId } = props
  const [saveLoading, setSaveLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  //项目
  const [projectName, setProjectName] = useState('')
  const [projectId, setProjectId] = useState('')
  const [projectVal, setProjectVal] = useState([])
  //产品
  const [productName, setProductName] = useState('')
  const [productId, setProductId] = useState('')
  const [productVal, setProductVal] = useState([])

  useEffect(() => {
    onEdit()
  }, [])

  const getSelectData = () => {
    api.getProjectOrProduct({ open: 1, projectId: 0 }).then(res => {
      // console.log(res)
      setProjectVal(res)
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  // 编辑框
  const onEdit = () => {
    getSelectData()
    setformLoading(true)
    if (addType == 1) {//eslint-disable-line
      api.getPerformanceProductConfigDetail({ id: editId }).then(res => {
        setDisabled(true)
        setformLoading(false)
        api.getProjectOrProduct({ open: 1, projectId: res.projectId }).then(res => {
          setProductVal(res)
        })
        setFieldsValue({
          gap: res.gap,
          money: res.money,
          productId: res.productId,
          projectId: res.projectId
        })
        setProjectName(res.project)
        setProjectId(res.projectId)
        setProductName(res.product)
        setProductId(res.productId)
      }).catch(err => setformLoading(false))
    } else {
      setformLoading(false)
    }
  }

  // 保存
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        // console.log(vals)
        if (editId) {
          vals.id = editId
        }
        setSaveLoading(true)
        let obj = {
          id: vals.id,
          gap: vals.gap,
          money: vals.money,
          productId: vals.productId,
          product: productName,
          projectId: vals.projectId,
          project: projectName
        }
        // console.log(obj)
        api.getPerformanceProductConfigHandle(obj).then(res => {
          // console.log(res)
          setSaveLoading(false)
          if (res.status == 0) {
            return message.warning('已存在')
          }else if (res.status == 1) {
            confirm({
              title: '',
              content: '已存在是否启用',
              onOk() {
                // console.log('是')
                // console.log(obj)
                api.getPerformanceProductConfigHandle({ ...obj, flag: 1 }).then(res => {
                  // console.log(res)
                  seteditVisible(false)
                  message.success('保存成功')
                  getPerformanceProductConfigList()
                })
              },
              onCancel() {},
            })
          } else {
            seteditVisible(false)
            message.success('保存成功')
            getPerformanceProductConfigList()
          }
        }).catch(() => setSaveLoading(false))
      }
    })
  }

  //选中项目名称
  const onprojectName = (key, value) => {
    // console.log(key, value.props.children)
    productId != '' && setFieldsValue({productId: ''})
    setProjectName(value.props.children)
    setProjectId(key)
  }

  //选中产品名称
  const onproductName = (key, value) => {
    // console.log(key, value.props.children)
    setProductName(value.props.children)
    setProductId(key)
  }

  //展开产品下拉获取列表
  const onproductList = () => {
    if (projectId == '') {
      return message.warning('请先选择项目')
    }
    api.getProjectOrProduct({ open: 1, projectId: projectId }).then(res => {
      // console.log(res)
      setProductVal(res)
    })
  }

  return (
    <div>
      <Form onSubmit={formSubmit} {...formItemLayout}>
        <FormItem label="项目名称">
          {getFieldDecorator('projectId', {
            rules: [{ required: true, message: '请选择项目名称' }]
          })(
            <Select 
              placeholder="请选择" 
              onSelect={onprojectName} 
              showSearch={true} 
              optionFilterProp="children"
              disabled={disabled}
            >
              {projectVal.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="产品名称">
          {getFieldDecorator('productId', {
            rules: [{ required: true, message: '请选择产品名称' }]
          })(
            <Select 
              placeholder="请选择" 
              showSearch={true} 
              optionFilterProp="children"
              onSelect={onproductName}
              onDropdownVisibleChange={bool => bool && onproductList()}
              disabled={disabled}
            >
              {productVal.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="时间自然日（天）">
          {getFieldDecorator('gap', {
            rules: [{ required: true, message: '请输入时间自然日（天）' }]
          })(
            <InputNumber placeholder="请输入" min={0} style={{width: '100%'}}/>
          )}
        </FormItem>
        <FormItem label="金额">
          {getFieldDecorator('money', {
            rules: [{ required: true, message: '请输入金额' }]
          })(
            <InputNumber placeholder="请输入金额" min={0} style={{width: '100%'}}/>
          )}
        </FormItem>
        <BtnGroup onCancel={() => seteditVisible(false)} loading={saveLoading} />
      </Form>
    </div>
  );
}

export default Form.create()(AddProductSet)

