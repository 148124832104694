/**
 * 模块名称: 订单业绩详情
 * @author wangyunhai@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import moment from 'moment'
import {
  Table,
  Form,
  Icon,
  Input,
  Button,
  Select,
  Modal,
  message,
  Alert,
  DatePicker,
  Spin
} from 'antd'

import CorpFilter from 'components/CorpFilter' 
import { parseSearch } from 'utils'
import Export from 'components/Export'
import Auth from '@/components/AuthMiddleware'
import CryptoJS from 'crypto-js'
let selectedIds = ''

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker, MonthPicker } = DatePicker

let pageSize = 10
let currentPage = 1
let companyId = []
let searchParamsTmp = {
  limit: pageSize, 
  page: currentPage,
  companyId: companyId
}
let storeFilterName = ''
let storeFilterContent = {}
let allFilter = {}

const PersonalList = (props) => {
  const { history, match, location } = props

  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form

  const productsRef = useRef()

  const [filterName, setFilterName] = useState(undefined)

  const [count, setCount] = useState(0)
  const [totalNum, setTotalNum] = useState('')
  const [list, setList] = useState([])
  const [filter, setFilter] = useState({})
  
  const [loading, setLoading] = useState(false)

  const [departmentList, setDepartmentList] = useState([])
  const [filterModelvisible, setFilterModelvisible] = useState(false)
  const [filterContent, setFilterContent] = useState([])
  const [showAll, setShowAll] = useState(false)
  const [showExport, setShowExport] = useState(false)
  const [checkedIdArr, setCheckedIdArr] = useState([])
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)
  const [authList, setAuthList] = useState([])
  const [clickFlag, setClickFlag] =  useState(true)
  const [listFlag, setListFlag] = useState(true)
  const [numFlag, setNumFlag] = useState(true)

  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage
    }
    storeFilterName = ''
    storeFilterContent = {}
    getListFilter()
    getPerfAdjList()
    getPerformanceListStat()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  //点击选择所属公司
  const onChangeCorp = (data) => {
    //console.log('onChangeCorp', data)
    setDepartmentList([])
    setFieldsValue({'departmentId': undefined})
    currentPage = 1
    history.replace(match.path)
    if(data.length) {
      let arr = []
      data.forEach((item, index) => {
        arr.push(item.id)
      })
      companyId = [].concat(arr)
      searchParamsTmp = {
        limit: pageSize, 
        page: currentPage,
        companyId: companyId
      }
    }else{
      companyId = []
      searchParamsTmp = {
        limit: pageSize, 
        page: currentPage
      }
    }
    getPerfAdjList()
    getPerformanceListStat()
  }
  //点击重置
  const onReset = () => {
    currentPage = 1
    history.replace(match.path)
    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage,
      companyId: companyId
    }
    resetFields()
    setFilterName(undefined)
    setStatusValName(undefined)
    getPerfAdjList()
    getPerformanceListStat()
  }

  //分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getPerfAdjList()
    getPerformanceListStat()
  }

  //获取列表
  const getPerfAdjList = () => {
    setList([])
    setLoading(true)
    if(companyId.length)searchParamsTmp.companyId = companyId
    setListFlag(false)
    api.perfProsonList(searchParamsTmp).then(res => { 
      setCount(res.count)
      setList(res.list)
      setListFlag(true)
      setLoading(false)
    }).catch(() => {
      setLoading(false)
      setListFlag(true)
    })
  }

  // 获取总数据
  const getPerformanceListStat = () => {
    if(companyId.length)searchParamsTmp.companyId = companyId
    setClickFlag(false)
    setNumFlag(false)
    api.getPerformanceListStat(searchParamsTmp).then(res => { 
      setTotalNum(res)
      setClickFlag(true)
      setNumFlag(true)
    }).catch(() => {
      setClickFlag(true)
      setNumFlag(true)
    })
  }
 
  //获取筛选项
  const getListFilter = () => {
    api.perfPersonListFilter().then(res => { 
      setFilter(res)
    }).catch(() => {
    })
  }
  //获取部门列表
  const getDepartmentList = () => {
    api.getDepartment({limit: global.paramsLimit, companyId}).then(res => {
      setDepartmentList(res.list)
    })
  }

  //数据转换
  const transData = (vals) => {
    if(vals !== undefined){
      for(let i in vals){
        if(vals[i] !== undefined){
          if(i == 'addTime'){
            searchParamsTmp.applySTime = moment(vals[i][0]).format('YYYY-MM-DD')
            searchParamsTmp.applyETime = moment(vals[i][1]).format('YYYY-MM-DD')
          } else if (i == 'perMonth') {
            searchParamsTmp.perMonth = moment(vals[i]).format('YYYY年MM月')
          } else{
            searchParamsTmp[i] = vals[i]
          }
        }
      }
      // console.log('searchParamsTmp=>', searchParamsTmp)
    }
  }
  //点击筛选
  const searchSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      //console.log(vals)
      transData(vals)
      if (!err) {
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getPerfAdjList()
        getPerformanceListStat()
        setFilterName(undefined)
      }
    })
    
  }
  //选择保存方案
  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容
    val && api.getSearchStorage({key: location.pathname}).then(res => {
      setFilterContent(res.list)
    })
  }
  //选择方案
  const onFilterChecked = (item, val2) => {
    // console.log('item=>', item)
    setFilterName(item)
    setStatusValName(val2.props.children.props.children[0])
    let data = filterContent[item].content
    searchParamsTmp = {...searchParamsTmp, ...data} 

    setFieldsValue({
      companyId: data.companyId ? data.companyId : undefined,
      stfNo: data.stfNo ? data.stfNo : undefined,
      stfName: data.stfName ? data.stfName : undefined,
      departmentId: data.departmentId ? data.departmentId : undefined,
      positionId: data.positionId ? data.positionId : undefined,
       
      business: data.business ? data.business : undefined,
      groupId: data.groupId ? data.groupId : undefined,
      superiorId: data.superiorId ? data.superiorId : undefined,
      stfStatus: data.stfStatus ? data.stfStatus : undefined,
      creditNo: data.creditNo ? data.creditNo : undefined,
      orderNo: data.orderNo ? data.orderNo : undefined, 
      orderType: data.orderType ? data.orderType : undefined,
      majorminor: data.majorminor ? data.majorminor : undefined,
      figtype: data.figtype ? data.figtype : undefined, 
      figSource: data.figSource ? data.figSource : undefined, 
      clientId: data.clientId ? data.clientId : undefined, 
      clientName: data.clientName ? data.clientName : undefined, 
      performanceId: data.performanceId ? data.performanceId : undefined,
      perMonth: data.perMonth ? moment(data.perMonth) : undefined,
      vendor: data.vendor ? data.vendor : undefined,
      addTime: data.addTime ? [moment(data.addTime[0]), moment(data.addTime[1])]: undefined
    })
    getDepartmentList()
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  //保存筛选条件
  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      //console.log(vals)
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item === '' || item !== undefined)) {
          storeFilterContent = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'addTime') {
                storeFilterContent[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
              } else {
                storeFilterContent[k] = vals[k]
              }
            }
          }
          setFilterModelvisible(true)
        } else {
          message.error('请选择筛选内容')
        }
      }
    })
  }
  //保存筛选点击确定
  const filterHandleOk = () => {
    validateFields((err, vals) => {   
      api.saveSearchStorage({
        name: storeFilterName,
        key: location.pathname,
        content: storeFilterContent
      }).then(() =>{
        setFilterModelvisible(false)
        message.success('添加成功')
      })
    })
  }
  //取消筛选点击确定
  const filterHandleCancel = () => {
    setFilterModelvisible(false)
  }
  //修改筛选名称
  const onFilterInfoChange = (e) => {
    storeFilterName = e.target.value
  }

  //导出
  const onExportFile = () => {
    setShowExport(true)
    //筛选初始值
    let exporfilter = {
      staffNumber: '',
      staffName: '',
      departmentId: '',
      orderNumber: '',
      orderType: '',
      orderMajorType: '',
      orderFigureType: '',
      applySTime: '', 
      applyETime: ''
    } 
    allFilter = Object.assign({}, exporfilter, searchParamsTmp)
    for(let i in allFilter){
      if(i == 'page' || i == 'limit')delete allFilter[i]
    }
    if (allFilter.companyId && allFilter.companyId.length > 0) {
      if (!Array.isArray(allFilter.companyId)) {
        allFilter.companyId = allFilter.companyId.split(',')
      }
    } else {
      allFilter.companyId = []
    }
    // console.log(allFilter)
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //console.log(selectedRowKeys, selectedRows)
      selectedIds = selectedRowKeys
    },
    onSelect: (record, selected, selectedRows) => {
      // console.log(record, selected, selectedRows)
      if (!selected) {
        for (let i = 0; i < checkedIdArr.length; i++) {
          if (checkedIdArr[i] == record.id) {//eslint-disable-line
            checkedIdArr.splice(i, 1)
            i--
          }
        }
      } else {
        checkedIdArr.push(record.id)
      }
      let arrNew = [...new Set(checkedIdArr)]
      setCheckedIdArr(arrNew)
      selectedIds = arrNew
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
    //全选一列（默认十条）
    onSelectAll: (selected, selectedRows, changeRows) => {
      //console.log(selected, selectedRows, changeRows)
      let arr = []
      changeRows.forEach(item => {
        arr.push(item.id)
      })
      if (selected) {
        setCheckedIdArr([...new Set([...checkedIdArr, ...arr])])
      } else {
        setCheckedIdArr(checkedIdArr.filter(item => !arr.includes(item)))
      }
    },
    selectedRowKeys: checkedIdArr
  }

  return (
    <>
      <CorpFilter onChange={onChangeCorp} clickFlag={clickFlag} />
      <div className="search-askforleave-wrap">
        <h4 className="title">其它选项：</h4>
        {
          !showAll ? <span className="askforleave-filter-control" onClick={() => setShowAll(true)}>展开<Icon type="down" /></span>
          : <span className="askforleave-filter-control" onClick={() => setShowAll(false)}>收起<Icon type="up" /></span>
        }
        <Form onSubmit={searchSubmit} {...formItemLayout}>
          <div className={`form-box${showAll ? ' askforleave-form-auto' : ''}`}>
            <FormItem label="员工编号">
              {getFieldDecorator('stfNo')(<Input 
                placeholder="请输入员工编号" 
              />)}
            </FormItem>
            <FormItem label="公司用名">
              {getFieldDecorator('stfName')(<Input 
                placeholder="请输入公司用名" 
              />)}
            </FormItem> 
            <FormItem label="部门">
              {getFieldDecorator('departmentId')(<Select
                placeholder="全部"  
                onDropdownVisibleChange={bool => bool && getDepartmentList()}
                showSearch
                optionFilterProp="children"
                dropdownMatchSelectWidth={false}
              >
                {
                  departmentList.map(v => {
                    return !companyId.length ? <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option> : <Option title={v.name} key={v.id}>{v.name}</Option>
                  })
                }
              </Select>)}
            </FormItem> 
            <FormItem label="职位">
              {getFieldDecorator('positionId')(<Select
                placeholder="全部"  
                dropdownMatchSelectWidth={false} 
              >
                {
                  filter.position && filter.position.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem> 
            <FormItem label="事业群">
              {getFieldDecorator('groupId')(<Select
                placeholder="全部"   
                showSearch
                optionFilterProp="children"
                dropdownMatchSelectWidth={false}
              >
                {
                  filter.group && filter.group.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem> 
            <FormItem label="直接主管">
              {getFieldDecorator('superior')(<Input 
                placeholder="请输入直接主管" 
              />)}
            </FormItem>
            <FormItem label="员工状态">
              {getFieldDecorator('stfStatus')(<Select
                placeholder="全部"   
                dropdownMatchSelectWidth={false}
              >
                {
                  filter.staffSta && filter.staffSta.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem> 
            <FormItem label="关联订单">
              {getFieldDecorator('orderNo')(<Input 
                placeholder="请输入关联订单" 
              />)}
            </FormItem>
            <FormItem label="订单类型">
              {getFieldDecorator('orderType')(<Select
                placeholder="全部"
                dropdownMatchSelectWidth={false}
              >
                {
                  filter.orderType && filter.orderType.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="主项/副项">
              {getFieldDecorator('majorminor')(<Select
                placeholder="全部"
                dropdownMatchSelectWidth={false}
              >
                {
                  filter.major && filter.major.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="业绩类型">
              {getFieldDecorator('figtype')(<Select
                placeholder="全部" 
                dropdownMatchSelectWidth={false}
              >
                {
                  filter.profitType && filter.profitType.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem> 
            <FormItem label="业绩来源">
              {getFieldDecorator('figSource')(<Select
                placeholder="全部" 
                dropdownMatchSelectWidth={false}
              >
                {
                  filter.profitSource && filter.profitSource.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem> 
            <FormItem label="代理商/客户ID">
              {getFieldDecorator('clientId')(<Input 
                placeholder="请输入QID/OA ID" 
              />)}
            </FormItem>
            <FormItem label="代理商/客户名称">
              {getFieldDecorator('clientName')(<Input 
                placeholder="请输入代理商/客户名称"
              />)}
            </FormItem>
            <FormItem label="订单业绩">
              {getFieldDecorator('performanceId')(<Input 
                placeholder="请输入订单业绩ID" 
              />)}
            </FormItem>
            <FormItem label="订单业绩月份">
              {getFieldDecorator('perMonth')(
                <MonthPicker placeholder="请选择" allowClear={false} />
              )}
            </FormItem>
            <FormItem label="厂商ID">
              {getFieldDecorator('vendor')(<Input 
                placeholder="请输入厂商ID" 
              />)}
            </FormItem>
            <FormItem label="业绩产生日期" style={{width: '40%'}}>
              {getFieldDecorator('addTime', {
              })(<RangePicker allowClear={false} />)}
            </FormItem>  
          </div>
          <FormItem className="btns" label=" " colon={false}>
            <div className="staff-filter-btns">
              {showAll && <>
                <h4 className="filter-title">已保存筛选方案：</h4>
                <div className="filter-select">
                  <Select style={{display: 'inline-block', width: '160px'}} placeholder="请选择" onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)} onSelect={onFilterChecked} value={filterName}> 
                    {filterContent.map((item, index) =>
                      <Option value={index} key={index}>
                        <div className="closeStyBtn">
                          {item.name}
                          {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                            e.stopPropagation()
                            onSearchDelete(item.id)
                          }} /></span> : null}
                        </div>
                      </Option>
                    )}
                  </Select>
                </div>
                <Button className="filter-savebtn" onClick={onSaveFilterTerm}>保存筛选条件</Button>
              </>}
              <Button onClick={onReset}>重置</Button>
              <Button type="primary" htmlType="submit" disabled={!listFlag || !numFlag}>筛选</Button>
              <Modal
                title="保存筛选条件"
                visible={filterModelvisible}
                onOk={filterHandleOk}
                onCancel={filterHandleCancel}
              >
                <Input placeholder="请输入筛选名称" onChange={onFilterInfoChange}/>
              </Modal>
            </div>
          </FormItem>   
        </Form>
      </div>
      <div className="line"></div>
      <div className="add-wrap">
        <Auth auths={authList} code="export">
          <Button type="primary" onClick={onExportFile} style={{ marginLeft: 15 }}>导出</Button>
        </Auth>
      </div>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl="getPerfAdjExportTpl"
        fieldsUrl="getPerfAdjTplItems"
        saveUrl="savePerfAdjExportTpls"
        exportUrl="exportPerfAdj"
        method="export"
        parame={{ ExportType: 'performance.export', id: selectedIds, ...allFilter }}
        type={1}
        cRef={productsRef}
      />
      {/* <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon /> */}
      <Spin spinning={!numFlag}>
        <Alert className="corp-count" message={<>
          <span>{`总计：${count}条数据`}</span>
          <span style={{ marginLeft: '20px' }}>{`订单业绩总数：${totalNum.total ? totalNum.total : 0} `}</span>
          <span style={{ marginLeft: '20px' }}>{`新开订单业绩总数：${totalNum.bill ? totalNum.bill : 0} `}</span>
          <span style={{ marginLeft: '20px' }}>{`续费订单业绩总数：${totalNum.rebill ? totalNum.rebill : 0} `}</span>
        </>} type="info" showIcon />
      </Spin>
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        loading={loading}
        className="askforleave-table"
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable}
        scroll={{x: 2600}}
        rowSelection={rowSelection}
      >
        <Column title="业绩产生日期" dataIndex="effectTime" width={120} fixed="left" />  
        <Column title="业绩" dataIndex="performance" />
        <Column title="关联订单" dataIndex="orderNo" render={(text, record) => 
          <>
            {record.figSourceName == "退单" ?
              <a target='_blank' href={`/order${record.orderType == '0' ? 'Direct/refund' : 'Agent/refund'}Detail?id=${record.orderId}&time=${+new Date()}&sign=${CryptoJS.MD5(record.orderId+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`} rel="noopener noreferrer">{text}</a>:
              <a target='_blank' href={`/order${record.orderType == '0' ? 'Direct/direct' : 'Agent/agent'}Detail?id=${record.orderId}&time=${+new Date()}&sign=${CryptoJS.MD5(record.orderId+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`} rel="noopener noreferrer">{text}</a>
            }
          </>
        } />  
        <Column title="客户名称" dataIndex="kehuName" />
        <Column title="订单类型" dataIndex="orderTypeName" />
        <Column title="主项/副项" dataIndex="majorminorName" />
        <Column title="业绩类型" dataIndex="figtypeName" />
        <Column title="业绩来源" dataIndex="figSourceName" />
        <Column title="员工编号" dataIndex="stfNo" />
        <Column title="公司用名" dataIndex="stfName" /> 
        <Column title="直接主管" dataIndex="superior" />
        <Column title="所属部门" dataIndex="department" />
        <Column title="职位" dataIndex="position" /> 
        <Column title="岗位级别" dataIndex="treatmentLevelName" />
        <Column title="员工状态" dataIndex="stfStatusName" /> 
        <Column title="经理" dataIndex="jingli" /> 
        <Column title="高级经理" dataIndex="gaojijingli" /> 
        <Column title="总监" dataIndex="zongjian" /> 
        <Column title="高级总监" dataIndex="gaojizongjian" /> 
        <Column title="副总" dataIndex="fuzong" /> 
        <Column title="高级副总" dataIndex="gaojifuzong" /> 
        <Column title="操作" fixed='right' width={120} key="oprate" render={(text, record) => {
          return <> 
            {record.allotFlag && <Button type="primary" size="small" onClick={() => {
              history.push('/performance/adjustment/apply', { orderId: record.orderId,perId:record.id})
            }}>业绩调整</Button>}
          </>
        }} />
      </Table> 
    </>
  )
}

export default Form.create()(PersonalList)