/**
 * 模块名称: 消耗业绩时间节点管理 -> 新增
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Form,
  Select,
  message,
  DatePicker
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import moment from 'moment'

const FormItem = Form.Item
const Option = Select.Option

const AddNodeManage = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  const { seteditVisible, getPerformanceTimeList, setformLoading, addType, editId } = props
  const [saveLoading, setSaveLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  //公司用名列表
  const [staffList, setStaffList] = useState([])
  //项目
  const [projectVal, setProjectVal] = useState([])
  //产品
  const [productVal, setProductVal] = useState([])
  //客户
  const [customerVal, setCustomerVal] = useState([])

  useEffect(() => {
    onEdit()
  }, [])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  // 编辑框
  const onEdit = () => {
    setformLoading(true)
    if (addType == 1) {//eslint-disable-line
      api.getPerformanceTimeInfo({ id: editId }).then(res => {
        // console.log(res)
        const data = res
        api.getPerformanceAllStaffList({ isOnJob: 1, isValidAccount: 0, staffName: res.staffName, limit: 1000 }).then(res => setStaffList(res.list))
        api.getPerformanceTimeStaffProSupplierList({ staffId: res.staffId}).then(res => {
          // console.log(res)
          let objProject = (res.filter(v => v.id == data.projectId))[0].childs
          let objProduct = (objProject.filter(v => v.id == data.productId))
          setProjectVal(res)
          setProductVal(objProject)
          objProduct.length > 0 && setCustomerVal(objProduct[0].childs)
          // console.log(objProduct)
        })
        setDisabled(true)
        setFieldsValue({
          staffId: data.staffId,
          projectId: data.projectId,
          productId: data.productId,
          customerId: data.customerId,
          nodeTime: moment(data.nodeTime)
        })
        setformLoading(false)
      }).catch(err => setformLoading(false))
    } else {
      setformLoading(false)
    }
  }

  // 保存
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        // console.log(vals, editId)
        if (editId) {
          vals.id = editId
        }
        setSaveLoading(true)
        let obj = {
          id: vals.id,
          nodeTime: vals.nodeTime.format('YYYY-MM-DD'),
          staffId: vals.staffId,
          projectId: vals.projectId,
          productId: vals.productId,
          customerId: vals.customerId
        }
        // console.log(obj)
        api.setPerformanceTimeSave(obj).then(res => {
          // console.log(res)
          setSaveLoading(false)
          seteditVisible(false)
          message.success('保存成功')
          getPerformanceTimeList()
        }).catch(err => setSaveLoading(false))
      }
    })
  }

  //选中项目名称，获取产品列表
  const onprojectName = (key) => {
    let objProject = (projectVal.filter(v => v.id == key))[0].childs
    setProductVal(objProject)
  }

  //选中产品名称,，获取客户列表
  const onproductName = (key) => {
    let objProduct = (productVal.filter(v => v.id == key))[0].childs
    setCustomerVal(objProduct)
  }

  //选中公司用名，获取项目列表
  const onStaffId = (key) => {
    // console.log(key)
    api.getPerformanceTimeStaffProSupplierList({ staffId: key}).then(res => {
      // console.log(res)
      setProjectVal(res)
    })
  }

  //日期限制
  const disabledDate = (current) => {
    // console.log('current', current)
    return current && current < moment().subtract(1, "day")
  }

  //公司用名模糊搜索
  const getStaffList = (val) => {
    if (val) {
			api.getPerformanceAllStaffList({
        isOnJob: 1,
        isValidAccount: 0,
        staffName: val,
        limit: 1000
      }).then(res => {
        // console.log(res)
        setStaffList(res.list)
      })
		} else {
			setStaffList([])
		}
  }

  // //选择客户名称
  // const oncustomerName = (key) => {
  //   console.log('客户', key)
  // }

  return (
    <div>
      <Form onSubmit={formSubmit} {...formItemLayout}>
        <FormItem label="公司用名">
          {getFieldDecorator('staffId', {
            rules: [{ required: true, message: '请选择公司用名' }]
          })(
            <Select 
              placeholder="请选择" 
              onSelect={onStaffId} 
              onSearch={getStaffList}
              showSearch={true} 
              optionFilterProp="children"
              disabled={disabled}
            >
              {staffList.map(item => <Option key={item.id} value={item.id}>{item.staffName}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="项目名称">
          {getFieldDecorator('projectId', {
            rules: [{ required: true, message: '请选择项目名称' }]
          })(
            <Select 
              placeholder="请选择" 
              onSelect={onprojectName} 
              showSearch={true} 
              optionFilterProp="children"
              disabled={disabled}
            >
              {projectVal.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="产品名称">
          {getFieldDecorator('productId', {
            rules: [{ required: true, message: '请选择产品名称' }]
          })(
            <Select 
              placeholder="请选择" 
              showSearch={true} 
              optionFilterProp="children"
              onSelect={onproductName}
              // onDropdownVisibleChange={bool => bool && onproductList()}
              disabled={disabled}
            >
              {productVal.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="客户名称">
          {getFieldDecorator('customerId', {
            rules: [{ required: true, message: '请选择客户名称' }]
          })(
            <Select 
              placeholder="请选择" 
              showSearch={true} 
              optionFilterProp="children"
              // onSelect={oncustomerName}
              // onDropdownVisibleChange={bool => bool && oncustomerList()}
              disabled={disabled}
            >
              {customerVal.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="时间">
          {getFieldDecorator('nodeTime', {
            rules: [{ required: true, message: '请选择时间' }]
          })(<DatePicker placeholder="请选择" disabledDate={disabledDate} style={{ width: '100%'}}/>)}
        </FormItem>
        <BtnGroup onCancel={() => seteditVisible(false)} loading={saveLoading} />
      </Form>
    </div>
  );
}

export default Form.create()(AddNodeManage)